import React from 'react';
import {Datagrid, FunctionField, List, RichTextField, TextField, usePermissions} from 'react-admin';
import {useStyles} from "../styles";
import {useTranslation} from "react-i18next";
import {CharityFilterAside} from "../filter/CharityFilterAside";
import hasPermission from "../auth/hasPermission";
import {Link} from "react-router-dom";
import {Button} from "@mui/material";


const MailSettingsList = (props) => {
    const classes = useStyles();

    const {t} = useTranslation();

    const {permissions} = usePermissions();

    const TabLinksField = ({ record }) => {
        const handleClick = (e) => {
            // This stops the click event from bubbling up to the row
            e.stopPropagation();
        };

        return (
            <div onClick={handleClick}>
                <Link to={`/mailSettings/${record.id}`}><Button variant="outlined">{t("Registration")}</Button></Link><br />
                <Link to={`/mailSettings/${record.id}/1`}><Button variant="outlined">{t("Reminder")}</Button></Link><br />
                <Link to={`/mailSettings/${record.id}/2`}><Button variant="outlined">{t("Daily new")}</Button></Link>
            </div>
        )
    };

    return (
        <List {...props} title={t('Mail settings')} perPage={50}
              aside={hasPermission(permissions, ["admin"], "charityFilter") ?
                  <CharityFilterAside showCharities={false}/> : null}>
            <Datagrid bulkActionButtons={false} rowClick="show" className={classes.root}>
                {hasPermission(permissions, ["show"], "umbrella") &&
                    <TextField source="charity_name" label={t('Charity')} sortable={false}/>
                }
                <TextField source="language_code" label={t('Language')} sortable={false}/>
                <RichTextField source="welcome_text" label={t('Welcome text')} sortable={false}/>
                <FunctionField render={record => <TabLinksField record={record} />} label={t('Actions')} />
            </Datagrid>
        </List>
    );
}

export default MailSettingsList;
