import {useListContext, usePermissions} from 'react-admin';
import Chip from '@mui/material/Chip';
import {useTranslation} from "react-i18next";
import {Avatar, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {axiosApiInstance} from "../AxiosUtils";

const FiltersDisplay = () => {
    const {permissions} = usePermissions();
    const { filterValues, setFilters } = useListContext();

    const rolePath = (permissions === 'admin' ? 'admin' : permissions === 'umbrella' ? 'umbrellad' : 'charitad');
    const urlPrefix = `${process.env.REACT_APP_API_URL}/api/v1/${rolePath}`;

    const [filterNames, setFilterNames] = useState({});
    const [filterAvatars, setFilterAvatars] = useState({});

    const {t} = useTranslation();

    const getChildName = async (id) => {
        await axiosApiInstance.get(`${urlPrefix}/children/${id}`).then((response) => {
            setFilterNames((prevNames) => ({
                ...prevNames,
                child_id: response.data.name,
            }));
            setFilterAvatars((prevAvatars) => ({
                ...prevAvatars,
                child_id: `${process.env.REACT_APP_CDN_URL}/${response.data.profile_image}.${response.data.image_ext}`,
            }));
        });
    };

    const getCharityName = async (id) => {
        await axiosApiInstance.get(`${urlPrefix}/charities/${id}`).then((response) => {
            setFilterNames((prevNames) => ({
                ...prevNames,
                charity_id: response.data.charity_name,
            }));
            setFilterAvatars((prevAvatars) => ({
                ...prevAvatars,
                charity_id: `${process.env.REACT_APP_CDN_URL}/${response.data.logo_name}.${response.data.ext}`,
            }));
        });
    };

    const getGuardianName = async (id) => {
        await axiosApiInstance.get(`${urlPrefix}/guardians/${id}`).then((response) => {
            setFilterNames((prevNames) => ({
                ...prevNames,
                user_id: response.data.given_name,
            }));
            setFilterAvatars((prevAvatars) => ({
                ...prevAvatars,
                user_id: `${process.env.REACT_APP_CDN_URL}/${response.data.avatar}`,
            }));
        });
    };

    useEffect(() => {
        Object.entries(filterValues).forEach(([key, value]) => {
            switch (key) {
                case 'child_id':
                    getChildName(value);
                    break;
                case 'charity_id':
                    getCharityName(value);
                    break;
                case 'user_id':
                    getGuardianName(value);
                    break;
                default:
                    break;
            }
        });

    }, [filterValues]);

    const handleClick = (value) => {
        // remove the clicked filter
        const newFilters = { ...filterValues };
        delete newFilters[value];
        setFilters(newFilters, {
            // remove the filter from the URL
            alwaysOn: false
        });
    };

    // check if there are filters other than the default 'q' filter
    const hasFilters = Object.keys(filterValues).length > 0;

    if(hasFilters) {
        return (
            <div style={{paddingLeft: 8, paddingTop: 8}}>
                <Typography sx={{ fontSize: 14, textTransform: 'uppercase', fontWeight: '700' }}>{t('Filters')}:</Typography>
                <div style={{ paddingTop: 4 }}>
                    {Object.entries(filterValues).map(([key, value]) => (
                        <Chip
                            key={`${key}-${value}`}
                            label={`${filterNames[key] || key}`}
                            avatar={<Avatar alt="Avatar" src={`${filterAvatars[key]}`} />}
                            onDelete={() => handleClick(key)}
                        />
                    ))}
                </div>
            </div>
        );
    } else {
        return <></>
    }
};

export default FiltersDisplay;
