import React from 'react';
import {Show, SimpleShowLayout, WithRecord} from 'react-admin';
import {Typography} from "@mui/material";
import parse from 'html-react-parser';
import {MailSettingsAside} from "./MailSettingsAside";

export const MailSettingsShow = (props) => {

    return (
        <Show {...props} aside={<MailSettingsAside/>}>
            <SimpleShowLayout>
                <WithRecord render={record =>
                    <Typography>
                        {record.logo_url &&
                            <img src={record.logo_url} alt="Url"/>
                        }
                        {parse(record.welcome_text)}
                        {parse(record.app_text_before)}
                        <div>
                            <table width="100%" cellSpacing="0" cellPadding="0" align="center">
                                <tr>
                                    <td>
                                        <table cellSpacing="0" cellPadding="0" align="center">
                                            <tr>
                                                <td align="center">
                                                    <a href='https://apps.apple.com/app/nofam/id1549552441'>
                                                        <img alt='Download it on the App Store'
                                                             src='https://nofam.org/assets/logo/app_store.png'
                                                             height="60"/></a>&nbsp;
                                                    <a href='https://play.google.com/store/apps/details?id=org.nofam.android.app'>
                                                        <img alt='Get it on Google Play'
                                                             src='https://nofam.org/assets/logo/google_play.png'
                                                             height="60"/></a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {record.language_code === "nl" &&
                            <div>
                                Open de app na het downloaden en klik op <u>inloggen</u> om het eerste bericht te zien dat klaarstaat!
                                Gebruik hiervoor het e-mailadres waarop deze mail ontvangen is en het volgende (tijdelijke) wachtwoord:
                                <strong> ABC123!</strong>
                                <br/><i>(Na inlog kan is deze te wijzigen naar een eigen wachtwoord)</i>
                            </div>
                        }
                        {record.language_code === "de" &&
                            <div>
                                Öffnen Sie die App nach dem Herunterladen und klicken Sie auf <u>anmelden</u>, um die erste Nachricht zu sehen, die bereitsteht!
                                Verwenden Sie dafür die E-Mail-Adresse, auf der diese Mail empfangen wurde, und das folgende (vorübergehende) Passwort:
                                <strong> ABC123!</strong>
                                <br/><i>(<i>(Nach dem Einloggen kann dieses in ein eigenes Passwort geändert werden)</i>)</i>
                            </div>
                        }
                        {record.language_code === "ru" &&
                            <>
                                Запустите приложение Nofam на своем телефоне и войдите в систему, используя адрес
                                электронной почты.
                                вы получили это письмо. <br/>Со следующим (временным) паролем вы получаете
                                доступ к приложению:
                                <strong>ABC123!</strong>
                                <br/><i>(После входа в систему вы можете изменить его на свой собственный пароль)</i>
                            </>
                        }
                        {record.language_code === "en" &&
                            <>
                                Start the Nofam app on your phone and log in with the e-mail address
                                you received this email. <br/>With the following (temporary) password you get
                                access to the app:
                                <strong>ABC123!</strong>
                                <br/><i>(After logging in you can change this to your own password)</i>
                            </>
                        }
                        {record.language_code === "hy" &&
                            <>
                                Գործարկեք Nofam հավելվածը ձեր հեռախոսում և մուտք գործեք էլ. փոստի հասցեով
                                դուք ստացել եք այս նամակը: <br/>Հետևյալ (ժամանակավոր) գաղտնաբառով դուք ստանում եք
                                մուտք դեպի հավելված.
                                <strong>ABC123!</strong>
                                <br/><i>(Մուտք գործելուց հետո դուք կարող եք դա փոխել ձեր սեփական գաղտնաբառով)</i>
                            </>
                        }
                        {record.language_code === "hi" &&
                            <>
                                अपने फ़ोन पर Nofam ऐप प्रारंभ करें और ई-मेल पते से लॉग इन करें
                                आपको यह ईमेल प्राप्त हुआ। <br/>निम्नलिखित (अस्थायी) पासवर्ड के साथ आपको मिलता है
                                ऐप तक पहुंच:
                                <strong>ABC123!</strong>
                                <br/><i>(लॉग इन करने के बाद आप इसे अपने पासवर्ड में बदल सकते हैं)</i>
                            </>
                        }
                        {parse(record.payment_iban)}
                        {parse(record.payment_other)}
                        {parse(record.regards_text)}
                        {parse(record.address_text)}
                    </Typography>
                }/>
            </SimpleShowLayout>
        </Show>
    );
}
