import React, {useEffect, useState} from 'react';
import {BooleanField, Datagrid, FunctionField, List, TextField, useGetList, usePermissions} from 'react-admin';
import {useStyles} from "../styles";
import {useTranslation} from "react-i18next";
import {Avatar, MenuItem, Select, TextField as MuiTextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {axiosApiInstance} from "../AxiosUtils";
import hasPermission from "../auth/hasPermission";

const UmbrellaList = (props) => {
    const classes = useStyles();

    const {t} = useTranslation();

    // We need to check the permissions of the users
    const {permissions} = usePermissions();

    const [locationChoices, setLocationChoices] = useState([]);
    const [umbrellaCharityId, setUmbrellaCharityId] = useState("");
    const [languageCode, setLanguageCode] = useState("");
    const [locationId, setLocationId] = useState("");

    const {data: locations} = useGetList(
        'locations',
        {
            sort: {field: 'title', order: 'ASC'},
            filter: {"umbrella_charity_id": umbrellaCharityId}
        },
    );

    useEffect(() => {
        if (locations && hasPermission(permissions, ["show"], "umbrella")) {
            let myLocationChoices = [];
            for (let i = 0; i < locations.length; i++) {
                let location = locations[i];
                let jsonOjbect = {name: `${location.title}`, id: location.id}
                myLocationChoices.push(jsonOjbect);
            }
            setLocationChoices(myLocationChoices);
        }
    }, [umbrellaCharityId, locations, permissions]);


    const [successAlert, setSuccessAlert] = useState(false);
    const [warningAlert, setWarningAlert] = useState(false);
    const [sending, setSending] = useState(false);
    const [importing, setImporting] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    const translateNonTranslated = () => {
        setSending(true);
        setSuccessAlert(false);
        setWarningAlert(false);
        setErrorText("");
        axiosApiInstance.post(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/translateNonTranslated/${umbrellaCharityId}/${languageCode}`
        ).then((response) => {
            if (response.status === 200) {
                setSending(false);
                setSuccessAlert(true);
                setSuccessText("Alles is vertaald");
                setWarningAlert(false);
            }
        }).catch((reason) => {
            setSending(false);
            setSuccessAlert(false);
            setSuccessText("");
            setWarningAlert(true);
            setErrorText(JSON.stringify(reason));
        })
    }

    const importData = () => {
        setImporting(true);
        setSuccessAlert(false);
        setWarningAlert(false);
        setErrorText("");
        axiosApiInstance.post(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/importData/${umbrellaCharityId}/${locationId}`
        ).then((response) => {
            if (response.status === 200) {
                setImporting(false);
                setSuccessAlert(true);
                setSuccessText("Data is geïmporteerd");
                setWarningAlert(false);
            }
        }).catch((reason) => {
            setImporting(false);
            setSuccessAlert(false);
            setSuccessText("");
            setWarningAlert(true);
            setErrorText(JSON.stringify(reason));
        })
    }

    return (
        <List {...props} title={t('Charities')}>
            <Datagrid bulkActionButtons={false} rowClick="show" className={classes.root} perPage={25}>
                <FunctionField render={record =>
                    <Avatar sx={{height: 40, width: 40}}
                            src={`${process.env.REACT_APP_CDN_URL}/${record.logo_name}.${record.ext}`}/>}/>
                />
                <TextField source="charity_name" label={t('Name')}/>
                <TextField source="city" label={t('City')}/>
                <TextField source="country" label={t('Country')}/>
                <BooleanField source="active" label={t('Is active')}/>
            </Datagrid>
            {hasPermission(permissions, ["show"], "umbrella") &&
                <div>
                    <div style={{marginTop: 16}}>
                        <MuiTextField id="language_code" label="(Optional) language to translate to" variant="outlined"
                                      style={{marginRight: 8}}
                                      onChange={(e) => setLanguageCode(e.target.value)}/>
                        <LoadingButton
                            onClick={translateNonTranslated}
                            loading={sending}
                            variant="outlined">{t('Translate all')}</LoadingButton>
                    </div>
                    <div style={{marginTop: 16}}>
                        <LoadingButton
                            onClick={importData}
                            loading={importing}
                            variant="outlined"
                            style={{marginRight: 8}}
                        >{t('Import data')}</LoadingButton>
                        <MuiTextField id="umbrella_charity_id" label="Umbrella Charity ID" variant="outlined"
                                      style={{marginRight: 8}}
                                      onChange={(e) => setUmbrellaCharityId(e.target.value)}/>
                        {umbrellaCharityId !== "" && locationChoices && locationChoices.length > 0 &&
                            <Select
                                id="location_id"
                                value={locationId}
                                label={t('Location')}
                                onChange={(e) => setLocationId(e.target.value)}
                            >
                                {locationChoices.map((location) => (
                                    <MenuItem key={location.id} value={location.id}>
                                        {location.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    </div>
                    <div>
                        {successAlert ? <Alert severity="success">
                            <AlertTitle>Succes</AlertTitle>
                            {successText}
                        </Alert> : <></>}

                        {warningAlert ? <Alert severity="warning">
                            <AlertTitle>Warning</AlertTitle>
                            {errorText}
                        </Alert> : <></>}
                    </div>
                </div>
            }
        </List>
    );
}

export default UmbrellaList;
