import React, {useEffect, useState} from 'react';
import {
    BooleanField,
    Datagrid,
    downloadCSV,
    FunctionField,
    List,
    NumberField,
    TextField,
    useGetList,
    usePermissions
} from 'react-admin';
import {useStyles} from "../styles";
import {useTranslation} from "react-i18next";
import {Avatar, MenuItem} from "@mui/material";
import Select from '@mui/material/Select';
import {CharityFilterAside} from "../filter/CharityFilterAside";
import hasPermission from "../auth/hasPermission";
import jsonExport from 'jsonexport/dist';
import {axiosApiInstance} from "../AxiosUtils";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";

const CharityList = (props) => {
    const classes = useStyles();

    const {t} = useTranslation();

    // We need to check the permissions of the users
    const {permissions} = usePermissions();

    const exporter = dataset => {
        const dataForExport = dataset.map(item => {
            const {
                backlinks, _links,
                translations, ...dataForExport
            } = item; // omit backlinks and author
            return dataForExport;
        });
        jsonExport(dataForExport, {
            headers: ['id', 'charity_name'] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'projecten'); // download as 'posts.csv` file
        });
    };

    const [locationChoices, setLocationChoices] = useState([]);
    const [locationId, setLocationId] = useState("");

    const {data: locations} = useGetList(
        'locations',
        {
            sort: {field: 'title', order: 'ASC'},
        },
    );

    useEffect(() => {
        if (locations && hasPermission(permissions, ["show"], "charities")) {
            let myLocationChoices = [];
            for (let i = 0; i < locations.length; i++) {
                let location = locations[i];
                let jsonOjbect = {name: `${location.title}`, id: location.id}
                myLocationChoices.push(jsonOjbect);
            }
            setLocationChoices(myLocationChoices);
        }
    }, [locations, permissions]);

    const [successAlert, setSuccessAlert] = useState(false);
    const [warningAlert, setWarningAlert] = useState(false);
    const [importing, setImporting] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    const importData = () => {
        setImporting(true);
        setSuccessAlert(false);
        setWarningAlert(false);
        setErrorText("");
        axiosApiInstance.post(
            `${process.env.REACT_APP_API_URL}/api/v1/umbrellad/importData/${locationId}`
        ).then((response) => {
            if (response.status === 200) {
                setImporting(false);
                setSuccessAlert(true);
                setSuccessText("Data is geïmporteerd");
                setWarningAlert(false);
            }
        }).catch((reason) => {
            setImporting(false);
            setSuccessAlert(false);
            setSuccessText("");
            setWarningAlert(true);
            setErrorText(JSON.stringify(reason));
        })
    }

    return (
        <List {...props} title={t('Charities')} sort={{field: 'charity_name', order: 'DESC'}} perPage={50}
              exporter={exporter}
              aside={hasPermission(permissions, ["admin"], "charityFilter") ?
                  <CharityFilterAside showCharities={false}/> : null}>
            <Datagrid bulkActionButtons={false} rowClick="show" className={classes.root}>
                <FunctionField render={record =>
                    <Avatar sx={{height: 40, width: 40}}
                            src={`${process.env.REACT_APP_CDN_URL}/${record.logo_name}.${record.ext}`}/>}/>
                />
                <TextField source="charity_name" label={t('Name')}/>
                {hasPermission(permissions, ["view"], "charity.umbrella_charity_name") &&
                    <TextField label={t('Umbrella')} source="parent_charity_name"/>
                }
                <TextField source="city" label={t('City')}/>
                <TextField source="country" label={t('Country')}/>
                <TextField source="nr_of_children" label={t('# of children')}/>
                <TextField source="nr_of_children_sponsored" label={t('# sponsored')}/>
                <TextField source="nr_of_volunteers" label={t('# of volunteers')}/>
                <TextField source="country" label={t('Country')}/>
                <NumberField
                    source="sponsorship_amount"
                    label={t('Sponsorship amount')}
                    locales={['en']}
                    options={{
                        style: 'currency',
                        currency: 'EUR',
                    }}
                />
                <BooleanField source="active" label={t('Is active')}/>
            </Datagrid>
            {hasPermission(permissions, ["show"], "charities") &&
                <div>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: 16}}>
                        <Typography sx={{m: 2}}>Importeer data uit OVAS voor projectlocatie:</Typography>
                        {locationChoices && locationChoices.length > 0 &&
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Select
                                    labelId="location-label"
                                    id="location_id"
                                    value={locationId}
                                    label="Locatie"
                                    onChange={(e) => setLocationId(e.target.value)}
                                    sx={{width: 200}}
                                >
                                    {locationChoices.map((location) => (
                                        <MenuItem key={location.id} value={location.id}>
                                            {location.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        }
                        {locationId > 0 &&
                            <LoadingButton
                                onClick={importData}
                                loading={importing}
                                variant="contained"
                                sx={{ml: 2}}
                            >Importeren</LoadingButton>
                        }
                    </div>
                    <div>
                        {successAlert ? <Alert severity="success">
                            <AlertTitle>Succes</AlertTitle>
                            {successText}
                        </Alert> : <></>}

                        {warningAlert ? <Alert severity="warning">
                            <AlertTitle>Warning</AlertTitle>
                            {errorText}
                        </Alert> : <></>}
                    </div>
                </div>
            }
        </List>
    );
}

export default CharityList;
