import React from 'react';
import {
    Create,
    Edit,
    required,
    SaveButton,
    SelectInput,
    TabbedForm,
    TextInput,
    Toolbar,
    usePermissions
} from 'react-admin';
import {useTranslation} from "react-i18next";
import {useUmbrellaChoices} from "../lib/customHooks";
import {FormatButtons, RichTextInput, RichTextInputToolbar} from 'ra-input-rich-text';
import {MailSettingsAside} from "./MailSettingsAside";
import hasPermission from "../auth/hasPermission";

const MailSettingsFormFields = () => {

    // We want to be able to translate the admin
    const {t} = useTranslation();

    // What permissions does the current user have?
    const {permissions} = usePermissions();

    // A volunteer belongs to a specific charity, retrieve the options here
    const umbrellaChoices = useUmbrellaChoices();

    // We need the choices before we can continue
    if (!umbrellaChoices) return null;

    const CustomToolbar = props => (
        <Toolbar
            {...props}
            sx={{display: 'flex', justifyContent: 'space-between'}}
        >
            <SaveButton/>
        </Toolbar>
    );

    const RTIToolbar = () => {
        return (<RichTextInputToolbar> <FormatButtons/> </RichTextInputToolbar>);
    }

    return (
        <TabbedForm redirect="edit" toolbar={<CustomToolbar/>}>
            <TabbedForm.Tab label={t("Registration mail")}>
                {hasPermission(permissions, ["view"], "umbrella") &&
                    <SelectInput label={t('Umbrella')} source="charity_id" choices={umbrellaChoices} validate={required()}/>
                }
                <SelectInput label={t('Language')} source="language_code"
                             choices={[
                                 {id: 'hy', name: t('hy')},
                                 {id: 'de', name: t('de')},
                                 {id: 'en', name: t('en')},
                                 {id: 'nl', name: t('nl')},
                                 {id: 'ru', name: t('ru')},
                             ]}
                defaultValue={"nl"}
                validate={required()}
                />
                <RichTextInput toolbar={<RTIToolbar/>} fullWidth label={t("Welcome text")} source="welcome_text"
                               validate={required()}/>
                <RichTextInput toolbar={<RTIToolbar/>} fullWidth label={t("App text before")} source="app_text_before"
                               validate={required()}/>
                <RichTextInput toolbar={<RTIToolbar/>} fullWidth label={t("Payment IBAN text")} source="payment_iban"/>
                <RichTextInput toolbar={<RTIToolbar/>} fullWidth label={t("Payment other text")} source="payment_other"/>
                <RichTextInput toolbar={<RTIToolbar/>} fullWidth label={t("Regards text")} source="regards_text"
                               validate={required()}/>
                <RichTextInput toolbar={<RTIToolbar/>} fullWidth label={t("Address text")} source="address_text"/>
                <TextInput label={t("Logo URL")} fullWidth source="logo_url"/>
            </TabbedForm.Tab>
            <TabbedForm.Tab label={t("Reminder for new posts")}>
                <TextInput label={t("Subject")} source="update_subject" fullWidth />
                <RichTextInput toolbar={<RTIToolbar/>} fullWidth label={t("Update pre text")} source="update_pre_text"/>
                <RichTextInput toolbar={<RTIToolbar/>} fullWidth label={t("Update post text")} source="update_post_text"/>
            </TabbedForm.Tab>
            <TabbedForm.Tab label={t("Daily new post mail")}>
                <TextInput label={t("Subject")} source="new_post_subject" fullWidth />
                <RichTextInput toolbar={<RTIToolbar/>} fullWidth label={t("New post pre text")} source="pre_text"/>
                <RichTextInput toolbar={<RTIToolbar/>} fullWidth label={t("New post post text")} source="post_text"/>
                <TextInput label="Update post test ID" source="test_update_post_id" />
            </TabbedForm.Tab>
        </TabbedForm>
    );
}

export const MailSettingsEdit = (props) => {
    const {t} = useTranslation();

    return (
        <Edit {...props} title={t('Edit mail settings')} aside={<MailSettingsAside/>} redirect="edit">
            <MailSettingsFormFields/>
        </Edit>
    );
}

export const MailSettingsCreate = (props) => {
    const {t} = useTranslation();

    return (
        <Create {...props} title={t('Create new mail settings')} aside={<MailSettingsAside/>} redirect="edit">
            <MailSettingsFormFields/>
        </Create>
    );
}
