import React from 'react';
import {DateField, Show, useRecordContext} from 'react-admin';
import {useTranslation} from "react-i18next";
import {Avatar, Box, TextField, Typography, Button} from "@mui/material";
import Grid from "@mui/material/Grid";
import "../App.css";
import CustomTextField from "../components/CustomTextField";
import CustomLabelField from "../components/CustomLabelField";
import CustomBooleanField from "../components/CustomBooleanField";
import {Link} from "react-router-dom";
import {getFormattedDateString} from "../DateHelpers";

const MailLogsShow = (props) => {
    const {t} = useTranslation();

    const GetUser = () => {
        const record = useRecordContext();

        if (record && record.nofam_user) {
            return <div style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                padding: 16
            }}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight: 16}}>
                    <Avatar alt={`${record.nofam_user.given_name} ${record.nofam_user.family_name}`}
                            src={`${process.env.REACT_APP_CDN_URL}/${record.nofam_user.avatar}`}
                            sx={{width: 56, height: 56}} style={{marginRight: 16}}/>
                    <Link to={`/guardians/${record.nofam_user.id}/show`}>
                        <Typography component="h6" variant="h6">{record.nofam_user.given_name} {record.nofam_user.family_name}</Typography>
                    </Link>
                </div>
            </div>
        }
    }

    const GetClickEvents = () => {

        const record = useRecordContext();

        if (record && record.mail_click_events && record.mail_click_events.length > 0) {
            return record.mail_click_events.map((clickEvent, i) => {
                return <div style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    padding: 16
                }} key={i}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight: 16}}>
                        {t(clickEvent.eventType)} : {getFormattedDateString(clickEvent.eventDateTime, 'D MMM YYYY HH:mm')}
                    </div>
                </div>
            })
        }
    }

    return (
        <Show {...props}>
            <div className="showie">
                <Grid container spacing={4} sx={{ padding: '8px' }} xs={12} lg={12}>
                    <Grid item xs={12}>
                        <div className="custom-box borderGreen">
                            <CustomLabelField source="mail_type" label={t('Mail Type')} />
                            {/* Voeg hier andere velden toe die je wilt weergeven */}
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <div className="custom-box">
                            <Typography variant="h6" gutterBottom>{t('Mail info')}</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <CustomLabelField source="sent_date_time" label={t('Sent Date Time')}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <CustomBooleanField source="read" label={t('Read')}/>
                                    <CustomLabelField source="read_date_time" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomLabelField source="subject" label={t('Subject')}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{
                                        fontWeight: 500,
                                        fontSize: 12
                                    }}>{t('Click events')}</Typography>
                                    <GetClickEvents />
                                </Grid>
                            </Grid>
                        </div>
                        {/* Voeg hier andere custom boxes toe voor contactgegevens, adres, enzovoort */}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <div className="custom-box">
                            <Typography variant="h6" gutterBottom>{t('Recipient info')}</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <GetUser />
                                </Grid>
                                <Grid item xs={2}>
                                    <CustomLabelField source="language_code" label={t('Language')} />
                                </Grid>
                                <Grid item xs={4}>
                                    <CustomLabelField source="last_access_date_time" label={t('Last access')} sortable={false}/>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="custom-box" style={{marginTop: '24px'}}>
                            <Typography variant="h6" gutterBottom>{t('Sender info')}</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomLabelField source="charity" label={t('Charity')}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomLabelField source="sender" label={t('Sender')}/>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Show>
    );
}

export default MailLogsShow;
