import React from 'react';
import {BooleanField, Datagrid, List, TextField} from 'react-admin';
import {useStyles} from "../styles";
import {useTranslation} from "react-i18next";
import {CharityFilterAside} from "../filter/CharityFilterAside";

const ExportSettingsList = (props) => {
    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <List {...props} title={t('Mail settings')} perPage={50}
              aside={<CharityFilterAside showCharities={false}/>}>
            <Datagrid bulkActionButtons={false} rowClick="show" className={classes.root}>
                <TextField source="parent_charity_name" label={t('Charity')} sortable={false}/>
                <TextField source="api_endpoint" label={t('Api endpoint')} sortable={false}/>
                <TextField source="ledger_id" label={t('Ledger ID')} sortable={false}/>
                <TextField source="contract_id" label={t('Contract ID')} sortable={false}/>
                <TextField source="import_user_name" label={t('Import username')} sortable={false}/>
                <BooleanField source="export_active" label={t('Is active')}/>
            </Datagrid>
        </List>
    );
}

export default ExportSettingsList;
