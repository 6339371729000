import React from 'react';
import {BooleanInput, Create, Edit, required, SelectInput, SimpleForm, TextInput, usePermissions} from 'react-admin';
import {useTranslation} from "react-i18next";
import {useUmbrellaChoices} from "../lib/customHooks";
import hasPermission from "../auth/hasPermission";

const ExportSettingsFormFields = () => {

    // We want to be able to translate the admin
    const {t} = useTranslation();

    // What permissions does the current user have?
    const {permissions} = usePermissions();

    // A volunteer belongs to a specific charity, retrieve the options here
    const umbrellaChoices = useUmbrellaChoices();

    // We need the choices before we can continue
    if (!umbrellaChoices) return null;

    return (
        <SimpleForm redirect="edit">
            {hasPermission(permissions, ["view"], "umbrella") &&
                <SelectInput label={t('Umbrella')} source="parent_charity_id" choices={umbrellaChoices}
                             validate={required()}/>
            }
            <TextInput source="api_endpoint" label={t('Api endpoint')} validate={required()}/>
            <TextInput source="api_key" label={t('Api key')} validate={required()}/>
            <TextInput source="ledger_id" label={t('Ledger ID')} validate={required()}/>
            <TextInput source="contract_id" label={t('Contract ID')} validate={required()}/>
            <TextInput source="category_id_sponsor_default" label={t('Category ID sponsor default')}
                       validate={required()}/>
            <TextInput source="category_id_sponsor_foreign" label={t('Category ID sponsor foreign')}
                       validate={required()}/>
            <TextInput source="category_id_sponsor_cancelled" label={t('Category ID sponsor cancelled')}
                       validate={required()}/>
            <TextInput source="import_user_name" label={t('Import username')}/>
            <TextInput source="import_password" label={t('Import password')}/>
            <BooleanInput source="export_active" label={t('Is active')}/>
        </SimpleForm>
    );
}

export const ExportSettingsEdit = (props) => {
    const {t} = useTranslation();

    return (
        <Edit {...props} title={t('Edit mail settings')}>
            <ExportSettingsFormFields/>
        </Edit>
    );
}

export const ExportSettingsCreate = (props) => {
    const {t} = useTranslation();

    return (
        <Create {...props} title={t('Create new mail settings')}>
            <ExportSettingsFormFields/>
        </Create>
    );
}
