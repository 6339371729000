import {useRecordContext} from "react-admin";
import {Box, TextField, Typography} from "@mui/material";
import React from "react";

export default function CustomTextField({source, label, multiline = false}) {
    const record = useRecordContext();

    return (
        <Box sx={{marginBottom: 2}}>
            <Typography variant="caption" display="block" gutterBottom>
                {label}
            </Typography>
            <TextField
                fullWidth
                variant="outlined"
                value={record[source]}
                multiline={multiline}
                disabled={true}
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#656565"
                    }, backgroundColor: '#f2f2f2'
                }}
            />
        </Box>
    );
};
