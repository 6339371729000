import React, {useEffect, useState} from 'react';
import {
    BooleanInput,
    Create,
    Edit,
    email,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TextInput,
    usePermissions,
    useRecordContext,
    WithRecord
} from 'react-admin';
import {useTranslation} from "react-i18next";
import {Avatar, FormControlLabel, Switch, Typography} from '@mui/material';
import {useCharityChoices, useUmbrellaChoices} from "../lib/customHooks";
import hasPermission from "../auth/hasPermission";
import Grid from "@mui/material/Grid";

const VolunteerFormFields = ({edit}) => {

    // We want to be able to translate the admin
    const {t} = useTranslation();

    // We need to check the permissions of the users
    const {permissions} = usePermissions();

    // A volunteer belongs to a specific charity, retrieve the options here
    const charityChoices = useCharityChoices();

    // A volunteer belongs to a specific charity, retrieve the options here
    const umbrellaChoices = useUmbrellaChoices();

    const record = useRecordContext();

    // Initial state matches the edit state (enabled for a new volunteer, disabled for edit)
    const [isEmailEditable, setEmailEditable] = useState(!edit);

    const umbrellaRoleChoices = [
        {id: 'UMBRELLA_ADMIN', name: 'Umbrella Admin'},
    ];

    const charityRoleChoices = [
        {id: 'CHARITY_ADMIN', name: 'Charity Admin'},
        {id: 'CHARITY_VOLUNTEER', name: 'Charity Volunteer'},
    ];

    const [volunteerOnUmbrellaLevel, setVolunteerOnUmbrellaLevel] = useState(false);
    const [realmChoices, setRealmChoices] = useState([]);

    useEffect(() => {
        if (record && record.charity_model)
            setVolunteerOnUmbrellaLevel(record.charity_model.umbrella);
    }, [record]);

    useEffect(() => {
        if (volunteerOnUmbrellaLevel) {
            setRealmChoices(umbrellaRoleChoices);
        } else {
            setRealmChoices(charityRoleChoices);
        }
    }, [volunteerOnUmbrellaLevel]);

    // We need the choices before we can continue
    if (!charityChoices) return null;

    return (
        <div class="showie">
            {!edit &&
                <Typography sx={{fontWeight: 'bold', fontSize: 20, mb: 2}}>
                    {t('Create volunteer')}
                </Typography>
            }
            {edit &&
                <Grid container spacing={4} sx={{padding: '8px'}} xs={12} lg={12}>
                    <Grid item xs={12}>
                        <div className="custom-box borderGreen">
                            <WithRecord
                                render={record =>
                                    <div className="rowit">
                                        <Avatar sx={{height: 100, width: 100, marginRight: '16px'}}
                                                src={`${process.env.REACT_APP_CDN_URL}/${record.avatar}`}/>
                                        <div>
                                            <Typography sx={{fontWeight: 700, fontSize: '24px'}}>
                                                {t('Edit')}: {record.salutation ? t(record.salutation) : ''} {record.given_name} {record.family_name}
                                            </Typography>
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
            }
            <SimpleForm redirect="show">
                <Grid container spacing={4} sx={{padding: '8px'}} xs={12} lg={12}>
                    <Grid item xs={12} lg={6} xl={6}>
                        <div className="custom-box">
                            <Typography variant="h6" gutterBottom>{t('General info')}</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <BooleanInput source="enabled" label={t('Enabled')}
                                                  helperText={t('Enabled helper text')}/>
                                </Grid>
                            </Grid>
                        </div>
                        <div class="custom-box" style={{marginTop: '24px'}}>
                            <Typography variant="h6" gutterBottom>{t('Contact details')}</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6} lg={6} xl={4}>
                                    <TextInput label={t('First name')} source="given_name" validate={required()}/>
                                </Grid>
                                <Grid item xs={6} lg={6} xl={4}>
                                    <TextInput label={t('Last name')} source="family_name" validate={required()}/>
                                </Grid>
                                <Grid item xs={12} sx={{flexDirection: "row", alignItems: 'center'}}>
                                    <TextInput source="email" label={t('email')} validate={[required(), email()]}
                                               sx={{width: 350}}
                                               disabled={!isEmailEditable}
                                               helperText={edit ? t('Edit email helper text') : ''}/>
                                    {edit &&
                                        <>
                                            <label>&nbsp;{t('Make editable')}</label>
                                            <input
                                                id="triggerEmailUpdate"
                                                type="checkbox"
                                                checked={isEmailEditable}
                                                onChange={() => setEmailEditable(!isEmailEditable)}
                                            />
                                        </>
                                    }
                                </Grid>
                                {hasPermission(permissions, ["create"], "guardians") && !edit &&
                                    <Grid item xs={6}>
                                        <TextInput label={t('Tmp password')} source="password" validate={required()}
                                                   sx={{mt: 3}} defaultValue="TempWw@Nf"/>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <div class="custom-box">
                            <Typography variant="h6" gutterBottom>{t('Coordinating')}</Typography>
                            <Grid container spacing={2}>
                                {hasPermission(permissions, ["showUmbrella"], "volunteers") &&
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            label={t('Add to umbrella?')}
                                            control={<Switch
                                                onChange={() => setVolunteerOnUmbrellaLevel(!volunteerOnUmbrellaLevel)}
                                                checked={volunteerOnUmbrellaLevel}/>}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={6}>
                                    {hasPermission(permissions, ["showUmbrella"], "volunteers") &&
                                        <div>
                                            {volunteerOnUmbrellaLevel &&
                                                <SelectInput
                                                    label={t('Umbrella')}
                                                    source={"charity_id"}
                                                    choices={umbrellaChoices}
                                                    validate={required()}
                                                />
                                            }
                                            {!edit && !volunteerOnUmbrellaLevel &&
                                                <SelectInput label={t('Charity')} source="charity_id" choices={charityChoices}
                                                             validate={required()}/>
                                            }
                                            {edit && !volunteerOnUmbrellaLevel &&
                                                <SelectArrayInput label={t('Projects')} source="charity_ids"
                                                                  choices={charityChoices} validate={required()}/>
                                            }
                                        </div>
                                    }
                                    {/*Upon creation the user is attached to one single charity*/}
                                    {permissions !== 'admin' && !edit &&
                                        <SelectInput label={t('Charity')} source="charity_id" choices={charityChoices}
                                                     validate={required()}/>
                                    }
                                    {permissions !== 'admin' && edit &&
                                        <SelectArrayInput label={t('Projects')} source="charity_ids"
                                                          choices={charityChoices} validate={required()}/>
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <div>
                                        <SelectInput source="realm_role" label={t('Role')} choices={realmChoices}
                                                     validate={required()}/>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </SimpleForm>
        </div>
    );
}

export const VolunteerEdit = (props) => {
    const {t} = useTranslation();

    return (
        <Edit {...props} title={t('Edit volunteer')} redirect="show">
            <VolunteerFormFields edit={true}/>
        </Edit>
    );
}

export const VolunteerCreate = (props) => {
    const {t} = useTranslation();

    return (
        <Create {...props} title={t('Add new volunteer')} redirect="edit">
            <VolunteerFormFields edit={false}/>
        </Create>
    );
}
