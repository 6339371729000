import {SvgIcon} from "@mui/material";
import AutoGraph from '@mui/icons-material/AutoGraph';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import FoundationIcon from '@mui/icons-material/Foundation';
import Umbrella from '@mui/icons-material/BeachAccess';
import AdminPanelSettingsIcon from '@mui/icons-material/EscalatorWarning';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import OutboxIcon from '@mui/icons-material/Outbox';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ErrorIcon from '@mui/icons-material/Error';
import LogIcon from '@mui/icons-material/TableRows';
import { ReactComponent as Guardian } from "./guardian.svg";
import { ReactComponent as Charity } from "./charityFounder.svg";
import { ReactComponent as SponsoredChild } from "./matched.svg";
import { ReactComponent as Child } from "./child.svg";
import { ReactComponent as Euro } from "./euroBag.svg";
import { ReactComponent as Posts } from "./posts.svg";
import NotificationsIcon from '@mui/icons-material/Notifications';
import {CheckCircle, Unpublished} from "@mui/icons-material";
import React from "react";


export const ChildDashboardIcon = () => (
    <SvgIcon style={{ fontSize: 24 }}>
        <Child />
    </SvgIcon>
);

export const CharityDashboardIcon = () => (
    <SvgIcon style={{ fontSize: 24 }}>
        <FoundationIcon sx={{color: "#f7941d"}} />
    </SvgIcon>
);

export const GuardianDashboardIcon = () => (
    <SvgIcon style={{ fontSize: 24 }}>
        <AdminPanelSettingsIcon sx={{color: "#2f3c4c"}} />
    </SvgIcon>
);

export const ChildPostsStatsIcon = () => (
    <SvgIcon style={{ fontSize: 24 }}>
        <AutoGraph sx={{color: "#2f3c4c"}} />
    </SvgIcon>
);

export const VolunteersDashboardIcon = () => (
    <SvgIcon style={{ fontSize: 24 }}>
        <VolunteerActivismIcon sx={{color: "#f7941d"}} />
    </SvgIcon>
);

export const MailSettingsIcon = () => (
    <SvgIcon style={{ fontSize: 24 }}>
        <ContactMailIcon sx={{color: "#f7941d"}} />
    </SvgIcon>
);

export const AppErrorsIcon = () => (
    <SvgIcon style={{ fontSize: 24 }}>
        <ErrorIcon sx={{color: "#f7941d"}} />
    </SvgIcon>
);

export const UserActionLogsIcon = () => (
    <SvgIcon style={{ fontSize: 24 }}>
        <LogIcon sx={{color: "#f7941d"}} />
    </SvgIcon>
);

export const MailLogsIcon = () => (
    <SvgIcon style={{ fontSize: 24 }}>
        <OutboxIcon sx={{color: "#f7941d"}} />
    </SvgIcon>
);

export const ExportSettingsIcon = () => (
    <SvgIcon style={{ fontSize: 24 }}>
        <ImportExportIcon sx={{color: "#f7941d"}} />
    </SvgIcon>
);

export const UmbrellaIcon = () => (
    <SvgIcon style={{ fontSize: 24 }}>
        <Umbrella sx={{color: "#f7941d"}} />
    </SvgIcon>
);

export const PostsDashboardIcon = () => (
    <SvgIcon style={{ fontSize: 24 }}>
        <Posts />
    </SvgIcon>
);

export const BellDashboardIcon = () => (
    <SvgIcon style={{ fontSize: 24, color: '#000000' }}>
        <NotificationsIcon />
    </SvgIcon>
);

export const CharityIcon = () => (
    <SvgIcon style={{ fontSize: 60 }}>
        <Charity />
    </SvgIcon>
);

export const EuroIcon = () => (
    <SvgIcon style={{ fontSize: 60 }}>
        <Euro />
    </SvgIcon>
);

export const GuardianIcon = () => (
    <SvgIcon style={{ fontSize: 60 }}>
        <Guardian />
    </SvgIcon>
);

export const ChildIcon = () => (
    <SvgIcon style={{ fontSize: 60 }}>
        <Child />
    </SvgIcon>
);

export const SponsoredChildIcon = () => (
    <SvgIcon style={{ fontSize: 60 }}>
        <SponsoredChild />
    </SvgIcon>
);

export const FalseIcon = (props) => {
    return <Unpublished sx={{color: "#ff7600"}} {...props} />
}
FalseIcon.muiName = "Unpublished";

export const TrueIcon = (props) => {
    return <CheckCircle sx={{color: "#84ba3f"}} {...props} />
}
TrueIcon.muiName = "CheckCircle";

export const BigFalseIcon = (props) => {
    return <Unpublished sx={{color: "#ff7600"}} style={{ fontSize: 34 }} {...props} />
}
FalseIcon.muiName = "Unpublished";

export const BigTrueIcon = (props) => {
    return <CheckCircle sx={{color: "#84ba3f"}} style={{ fontSize: 34 }} {...props} />
}
TrueIcon.muiName = "CheckCircle";

export const SmallFalseIcon = (props) => {
    return <Unpublished sx={{color: "#ff7600"}} style={{ fontSize: 16 }} {...props} />
}
FalseIcon.muiName = "Unpublished";

export const SmallTrueIcon = (props) => {
    return <CheckCircle sx={{color: "#84ba3f"}} style={{ fontSize: 16 }} {...props} />
}
TrueIcon.muiName = "CheckCircle";
