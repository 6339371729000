import React from 'react';
import {Datagrid, DateField, List, TextField, usePermissions} from 'react-admin';
import {useStyles} from "../styles";
import {useTranslation} from "react-i18next";
import {CharityFilterAside} from "../filter/CharityFilterAside";
import hasPermission from "../auth/hasPermission";

const ActivityList = (props) => {
    const classes = useStyles();

    const {t} = useTranslation();

    const {permissions} = usePermissions();

    return (
        <List {...props} title={t('Activities')} sort={{field: 'create_date_time', order: 'DESC'}} perPage={20}
              aside={hasPermission(permissions, ["default"], "charityFilter") ? <CharityFilterAside/> : null}
        >
            <Datagrid bulkActionButtons={false} rowClick="show" className={classes.root}>
                <TextField source="activity" label={t('Notificatie type')}/>
                <TextField source="additional_value" label={t('Post ID')}/>
                <TextField source="description" label={t('Description')}/>
                <TextField source="guardian_name" label={t('Trigger for guardian')}/>
                <TextField source="child_name" label={t('Applicable for child')}/>
                <DateField label={t('Trigger date')} source="create_date_time"/>
            </Datagrid>
        </List>
    );
}

export default ActivityList;
