import {AppBar, Layout, Sidebar, UserMenu} from "react-admin";
import React from "react";

const MySidebar = (props) => (
    <Sidebar
        sx={{
            "& .RaSidebar-fixed": {
                backgroundImage: 'linear-gradient(195deg, rgb(116, 171, 45), rgb(28, 47, 3))',
                borderRadius: '9px',
                color: '#FFFFFF',
                height: 'calc(100vh - 2em) !important',
            },
            "& .RaMenuItemLink-active": {
                fontWeight: '700',
                backgroundColor:'#FFFFFF !important',
                borderRadius: '9px',
                marginX: '6px',
                color: '#2f3c4c !important',
            },
            "& .MuiMenuItem-root": {
                lineHeight: '2rem',
                color: '#FFFFFF'
            },
        }}
        {...props}
    />
);

const MyUserMenu = props => (<UserMenu label={localStorage.getItem('username')} {...props}/>);
const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu/>} color='secondary' sx={{color: '#ffffff'}}/>;
export const NofamLayout = props => <Layout {...props} appBar={MyAppBar} sidebar={MySidebar}/>;
