import UmbrellaList from "./UmbrellaList";
import dataProvider from "../App";
import {UmbrellaIcon} from "../icons/icons";
import UmbrellaShow from "./UmbrellaShow";
import {UmbrellaCreate, UmbrellaEdit} from "./UmbrellaCRUD";

const UmbrellaResource = {
    options: { label: 'Umbrella', icon: UmbrellaIcon },
    name: 'umbrellas',
    list: UmbrellaList,
    show: UmbrellaShow,
    edit: UmbrellaEdit,
    create: UmbrellaCreate,
    getList: async (resource, params) => {
        const { data } = await dataProvider.getList('charities', {
            ...params,
            filter: { umbrella: true },
        });
        console.log("RES:" + JSON.stringify(data));
        return {
            data,
            total: data.length,
        };
    },
};

export default UmbrellaResource;
