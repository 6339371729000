import React, {createElement, useEffect, useState} from 'react';
import {Box, Card, CardContent, CardHeader, MenuItem, Select} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {getCharityDashboard, getDashboard} from "./DashboardAPI";
import CardWithIcon from "../components/CardWithIcon";
import PostChart from "./PostChart";
import NewChildren from "./NewChildren";
import NewGuardians from "./NewGuardians";
import NewPosts from "./NewPosts";
import {usePermissions} from 'react-admin';
import {CharityIcon, ChildIcon, EuroIcon, GuardianIcon, SponsoredChildIcon} from "../icons/icons";
import cartoucheDark from "../cartoucheDark.png";
import cartouche from "../cartouche.png";
import {useTranslation} from "react-i18next";
import theme from '../themes';
import hasPermission from "../auth/hasPermission";
import useUmbrellasAndCharities from "../filter/useUmbrellasAndCharities";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const Dashboard = () => {

    // Set default values
    const {permissions} = usePermissions();
    const classes = useStyles();

    const {t} = useTranslation();

    const [isLoaded, setIsLoaded] = useState(false);

    // Numbers
    const [currentUser, setCurrentUser] = useState('');
    const [childrenCount, setChildrenCount] = useState('');
    const [childrenSponsoredCount, setChildrenSponsoredCount] = useState('');
    const [guardianCount, setGuardianCount] = useState('');
    const [monthlyDonationAmount, setMonthlyDonationAmount] = useState('');
    const [postChart, setPostChart] = useState('');

    const [charityId, setCharityId] = useState(0);
    const [umbrellaId, setUmbrellaId] = useState(0);

    const handleCharityChange = (event) => {
        setCharityId(event.target.value);
    };

    const handleUmbrellaChange = (event) => {
        setUmbrellaId(event.target.value);
    };

    // Retrieve the umbrella and charities
    const {umbrellas, charities} = useUmbrellasAndCharities({'umbrellaId': umbrellaId});

    /**
     * Set data retrieved from the backend
     * @param data
     */
    const setData = (data) => {
        setCurrentUser(data.current_user);
        setChildrenCount(data.nr_of_children);
        setGuardianCount(data.nr_of_guardians);
        setChildrenSponsoredCount(data.nr_of_children_sponsored);

        // Since most umbrellas are based in the EU we use this formatting
        const formattedMonthlyDonation = new Intl.NumberFormat(navigator.language, {
            style: 'currency',
            currency: 'EUR',
        }).format(data.monthly_donation);
        setMonthlyDonationAmount(formattedMonthlyDonation);

        setPostChart(data.post_chart);
        setIsLoaded(true);
    }

    useEffect(() => {
        if (hasPermission(permissions, ["admin"], "dashboard")) {
            getDashboard(permissions, umbrellaId, charityId).then((response) => {
                setData(response.data);
            }).catch(function (error) {
                console.log('There has been a problem with the getStatistics operation: ' + error.message);
            });
        } else if (hasPermission(permissions, ["charity"], "dashboard")) {
            getCharityDashboard().then((response) => {
                setData(response.data);
            }).catch(function (error) {
                console.log('There has been a problem with the getStatistics operation for a charity: ' + error.message);
            });
        }
    }, [umbrellaId, charityId, permissions]);

    // As an admin we should wait for charities to be loaded
    if (!charities && hasPermission(permissions, ["*"], "charities")) return null;

    return isLoaded ? (
        <Card style={{backgroundColor: theme.palette.background.default}}>
            <CardHeader title={`${t('Welcome')}  ${currentUser?.given_name}`}/>
            <CardContent>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={3}
                >
                    {hasPermission(permissions, ["list"], "umbrella") &&
                        <Grid item xs={12} sm={12} md={4} xl={3}>
                            <Card
                                sx={{
                                    minHeight: 52, display: 'flex', flexDirection: 'column', flex: '1',
                                    '& a': {textDecoration: 'none', color: 'inherit',},
                                }}
                            >
                                <Box
                                    sx={{
                                        overflow: 'inherit', padding: '16px', display: 'flex',
                                        background: theme =>
                                            `url(${
                                                theme.palette.mode === 'dark'
                                                    ? cartoucheDark
                                                    : cartouche
                                            }) no-repeat`,
                                        justifyContent: 'space-between', alignItems: 'center',
                                    }}
                                >
                                    <Box width="3em" className="icon">
                                        {createElement(CharityIcon, {fontSize: 'large'})}
                                    </Box>
                                    <Box textAlign="right">
                                        <Select
                                            label="Umbrella"
                                            id="selected_umbrella"
                                            value={umbrellaId}
                                            onChange={handleUmbrellaChange}
                                        >
                                            <MenuItem key={-1}
                                                      value={0}>{`${t('All')} ${umbrellas.length} ${t('umbrellas')}`}</MenuItem>
                                            {umbrellas
                                                ? umbrellas.map((record, index) => (
                                                    <MenuItem key={index}
                                                              value={record.id}>{record.charity_name} {record.active ? '' : '(inactive)'}</MenuItem>
                                                ))
                                                : null
                                            }
                                        </Select>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    }
                    {hasPermission(permissions, ["*"], "charities") &&
                        <Grid item xs={12} sm={12} md={4} xl={4}>
                            <Card
                                sx={{
                                    minHeight: 52, display: 'flex', flexDirection: 'column', flex: '1',
                                    '& a': {textDecoration: 'none', color: 'inherit',},
                                }}
                            >
                                <Box
                                    sx={{
                                        overflow: 'inherit', padding: '16px', display: 'flex',
                                        background: theme =>
                                            `url(${
                                                theme.palette.mode === 'dark'
                                                    ? cartoucheDark
                                                    : cartouche
                                            }) no-repeat`,
                                        justifyContent: 'space-between', alignItems: 'center',
                                    }}
                                >
                                    <Box width="3em" className="icon">
                                        {createElement(CharityIcon, {fontSize: 'large'})}
                                    </Box>
                                    <Box textAlign="right">
                                        <Select
                                            label="Charity"
                                            id="selected_charity"
                                            value={charityId}
                                            onChange={handleCharityChange}
                                        >
                                            <MenuItem key={-1}
                                                      value={0}>{`${t('All')} ${charities.length} ${t('charities')}`}</MenuItem>
                                            {charities
                                                ? charities.map((record, index) => (
                                                    <MenuItem key={index}
                                                              value={record.id}>{record.charity_name} {record.active ? '' : '(inactive)'}</MenuItem>
                                                ))
                                                : null
                                            }
                                        </Select>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    }
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={3}
                    paddingTop={3}
                >
                    <Grid item xs={12} sm={12} md={4} xl={2}>
                        <CardWithIcon
                            to="/children"
                            icon={ChildIcon}
                            title={t('# of children')}
                            subtitle={childrenCount}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} xl={2}>
                        <CardWithIcon
                            to="/children"
                            icon={SponsoredChildIcon}
                            title={t('# sponsored')}
                            subtitle={childrenSponsoredCount}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} xl={2}>
                        <CardWithIcon
                            to="/guardians"
                            icon={GuardianIcon}
                            title={t('# of guardians')}
                            subtitle={guardianCount}
                        />
                    </Grid>
                    {hasPermission(permissions, ["view"], "charity.donations") &&
                        <Grid item xs={12} sm={12} md={4} xl={3}>
                            <CardWithIcon
                                to="/"
                                icon={EuroIcon}
                                title={t('Monthly donation amount')}
                                subtitle={monthlyDonationAmount}
                            />
                        </Grid>
                    }
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={3}
                    paddingTop={3}
                >
                    <Grid item xs={12} md={6} lg={4}>
                        <Paper className={classes.paper}>
                            <NewChildren umbrellaId={umbrellaId} charityId={charityId}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Paper className={classes.paper}>
                            <NewGuardians umbrellaId={umbrellaId} charityId={charityId}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper className={classes.paper}>
                            <NewPosts umbrellaId={umbrellaId} charityId={charityId}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        {postChart &&
                            <PostChart posts={postChart}/>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    ) : null;
}

export default Dashboard;
