import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {axiosApiInstance} from "../AxiosUtils";
import {Button} from "@mui/material";
import CommentItem from "./CommentItem";
import CommentInput from "./CommentInput";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

export const loadCommentsForPost = async (postId) =>
    axiosApiInstance.get(`${process.env.REACT_APP_API_URL}/api/v1/updatepost/${postId}/comments`);

export const deleteComment = (postId, commentId) =>
    axiosApiInstance.delete(`${process.env.REACT_APP_API_URL}/api/v1/updatepost/${postId}/comment/${commentId}`);

export const postComment = async (postId, message, commentId = 0) => {
    let formData = new FormData();
    formData.append("message", message);
    let url = `${process.env.REACT_APP_API_URL}/api/v1/updatepost/${postId}/comment`;

    // Are we posting a reaction?
    if (commentId > 0) {
        url = url + `/${commentId}`;
    }

    return axiosApiInstance.post(url, formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        });
};

export const likeComment = async (postId, commentId, liked) => {
    let formData = new FormData();
    formData.append("liked", liked);

    return axiosApiInstance.post(`${process.env.REACT_APP_API_URL}/api/v1/updatepost/${postId}/comment/${commentId}/liked`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        });
};

export default function PostComments({postId}) {

    const {t} = useTranslation();

    const [comments, setComments] = useState([]);
    const [replyCommentId, setReplyCommentId] = useState(0);
    const [replyingToMessage, setReplyingToMessage] = useState("");
    const [open, setOpen] = React.useState(false);
    const [commentId, setCommentId] = useState(0);

    const commentInputRef = useRef();

    /* Load the post comments for the earlier specified post ID
     * @return {void}
     */
    const _loadPostComments = (postId) => {
        // Retrieve the child posts for the current child
        loadCommentsForPost(postId).then((response) => {
            if (response.status === 200 && response.data) {
                // @ts-ignore
                setComments(response.data.comments);
            }
        }).catch(err => {
            console.log('Not able to load child posts with error: ' + err.message());
        });
    }

    // On screen load, load the posts comments (and each change of post ID
    useEffect(() => {
        _loadPostComments(postId);
    }, [postId]);

    /**
     * Toggles the like status of a comment and updates the comments list.
     *
     * @param {object} comment - The comment object to toggle like status for.
     */
    const _onToggleLikeComment = async (comment) => {
        if (comment?.id) {
            // Comment is (!)liked by the user
            await likeComment(postId, comment.id, !comment.ilike).then((response) => {
                if (response.status === 200 && response.data) {
                    _loadPostComments(postId);
                }
            }).catch(err => {
                console.log('Not able to like comment with error: ' + err.message());
            });
        }
    }


    /**
     * Function to post a comment or reaction on a post.
     * @param {string} message - The message to be posted as a comment or reaction.
     * @param {number} commentId - The ID of the comment that is being reacted to. Default value is 0 for a new comment.
     */
    const _postComment = (message, commentId = 0) => {
        postComment(postId, message, commentId).then((response) => {
            if (response.status === 200 && response.data) {
                _loadPostComments();
                setReplyCommentId(0);
                setReplyingToMessage("");
            }
        }).catch(err => {
            console.log('Not able to post comment: ' + err.message());
        });
    }


    /**
     * Sets the focus on the comment input field, stores the message being replied to, and sets the comment ID being replied to
     *
     * @param {number} commentId - ID of the comment being replied to
     * @param {string} commentMessage - Message of the comment being replied to
     */
    const _onReply = (commentId, commentMessage) => {
        commentInputRef.current?.focus()
        setReplyingToMessage(commentMessage);
        setReplyCommentId(commentId)
    }

    /**
     * Deletes a comment from the post with the specified ID.
     */
    const handleDelete = () => {
        deleteComment(postId, commentId)
            .then((response) => {
                if (response.status === 200) {
                    _loadPostComments(postId);
                    handleClose();
                }
            })
            .catch((err) => {
                console.log('Not able to delete comment: ' + err.message());
            });
    }

    const handleClickDelete = (commentId) => {
        setCommentId(commentId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div style={{backgroundColor: "#f0f0f0", padding: "20px", width: "800px", height: '100%'}}>
            {comments && comments.map((comment, index) => (
                <div style={{height: "100%"}}>
                    <CommentItem comment={comment} key={index} onReply={_onReply}
                                 onToggleLikeComment={_onToggleLikeComment} onDelete={handleClickDelete}/>
                    {comment.reactions && comment.reactions.map((comment, index) => (
                        <CommentItem comment={comment} key={index} onToggleLikeComment={_onToggleLikeComment}
                                     onDelete={handleClickDelete} isReaction={true}/>
                    ))}
                </div>
            ))}
            {replyingToMessage && replyingToMessage !== "" &&
                <div><i>{`${t('Replying to')} : ${replyingToMessage}`}</i>&nbsp;-&nbsp;<Button
                    onClick={() => _onReply(0, "")}>{t('Reply to post')}</Button></div>
            }
            <CommentInput onSubmit={_postComment} commentInputRef={commentInputRef} commentId={replyCommentId} />

            <Dialog open={open} onClose={handleClose}
                    aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t('Delete comment alert title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('Delete comment alert message')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>{t('No, cancel')}</Button>
                    <Button onClick={handleDelete}>{t('Yes, delete')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
