import moment from "moment/moment";
import React, {useEffect, useState} from "react";
import i18n from "i18next";
import {Box, Button, IconButton} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {Favorite, FavoriteBorderOutlined} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {usePermissions} from "react-admin";
import hasPermission from "../auth/hasPermission";

export default function CommentItem({comment, onReply, onToggleLikeComment, onDelete, isReaction = false}) {
    const userName = comment.nofam_user_model.given_name + ' ' + comment.nofam_user_model.family_name;
    const timePosted = moment(comment.created_time).fromNow();

    const {t} = useTranslation();

    // We need to check the permissions of the users
    const {permissions} = usePermissions();

    // User avatar
    let avatarUrl;
    if (comment.nofam_user_model.avatar_thumbnail && comment.nofam_user_model.avatar_thumbnail !== "") {
        avatarUrl = `${process.env.REACT_APP_CDN_URL}/${comment.nofam_user_model.avatar_thumbnail}`;
    }

    const [message, setMessage] = useState("");
    const [originalMessage, setOriginalMessage] = useState("");
    const [showOriginal, setShowOriginal] = useState(false);
    const [mine, setMine] = useState(false);

    // Set the comment values
    useEffect(() => {
        setMessage(comment.message);
        setMine(comment.mine);

        if (!comment.mine && comment.translations && comment.translations.length > 0) {
            for (let trans of comment.translations) {
                if (trans.languageCode === i18n.language) {
                    setMessage(trans.translation);
                    // It can be that the original text is the translation we get, in that case we don't have to
                    // show the original message, otherwise present the user the option to show the original message
                    if (!trans.original) {
                        setOriginalMessage(comment.message);
                    }
                }
            }
        }
    }, [comment]);

    /**
     * User wants to reply on a comment (identified by id)
     */
    const _onReply = () => {
        if (comment.id) {
            onReply(comment.id, showOriginal && originalMessage !== "" ? originalMessage : message);
        }
    }

    return (
        <Box
            sx={{
                padding: '16px',
                backgroundColor: '#FFFFFF',
                marginBottom: '16px',
                ...(isReaction && {
                    marginLeft: '40px',
                    borderLeftColor: '#b0acac',
                    borderLeftWidth: '10px',
                    borderLeftStyle: 'solid'
                }),
                borderRadius: '9px',
                height: '100%',
            }}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <Avatar src={avatarUrl} alt={userName} sx={{mr: 1}}/>
                    <Box>
                        <Box component="span" fontWeight="fontWeightBold">
                            {userName}
                        </Box>
                        <Box component="span" color="text.secondary" fontSize="caption.fontSize">
                            &nbsp; {timePosted}
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    <IconButton size="small" aria-label="like" onClick={() => onToggleLikeComment(comment)}>
                        {comment.ilike &&
                            <Favorite fontSize="small" color="#F00"/>
                        }
                        {!comment.ilike &&
                            <FavoriteBorderOutlined fontSize="small"/>
                        }
                    </IconButton>
                    <Box component="span" color="text.secondary" fontSize="caption.fontSize">
                        {comment.likes}
                    </Box>
                </Box>
            </Box>
            <Box mt={2}>
                {showOriginal && originalMessage !== "" ? originalMessage : message}
                <div style={{flexDirection: 'row', display: 'flex'}}>
                    {!mine && !isReaction &&
                        <Button
                            onClick={_onReply}
                            size="small"
                        >{t('Reply')}</Button>
                    }
                    {!mine && originalMessage && originalMessage !== "" &&
                        <Button
                            onClick={() => setShowOriginal(!showOriginal)}
                            size="small"
                        >{showOriginal ? t('Show translation') : t('Show original')}</Button>
                    }
                    {hasPermission(permissions, ["delete"], "post.comment") &&
                        <Button
                            onClick={() => onDelete(comment.id)}
                            size="small"
                        >{t('Delete')}</Button>
                    }
                </div>
            </Box>
        </Box>
    );
};
