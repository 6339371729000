import React, {useEffect, useState} from 'react';
import {Avatar, ImageList, ImageListItem} from '@mui/material';
import {List, TextInput, useCreatePath, useListContext, usePermissions} from 'react-admin';
import {Link} from 'react-router-dom';
import ReactPlayer from "react-player";
import {CharityFilterAside} from "../filter/CharityFilterAside";
import CardHeader from '@mui/material/CardHeader';
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import {makeStyles} from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from "moment";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FilterDisplay from "../components/FilterDisplay";
import hasPermission from "../auth/hasPermission";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(2),
    },
    card: {
        borderRadius: theme.spacing(1),
        boxShadow: theme.shadows[4],
        margin: 8,
    },
    media: {
        paddingTop: '56.25%',
    },
    content: {
        padding: theme.spacing(2),
    },
}));

const PostGrid = () => {
    const {data} = useListContext();
    const createPath = useCreatePath();

    const classes = useStyles();

    const isXxl = useMediaQuery('(min-width: 2000px)');
    const isXl = useMediaQuery('(min-width: 1536px)');
    const isLg = useMediaQuery('(min-width: 1200px)');
    const isMd = useMediaQuery('(min-width: 900px)');
    const isSm = useMediaQuery('(min-width: 600px)');

    const [cols, setCols] = useState(window.innerWidth * 0.7);

    // On screen load, load the posts comments (and each change of post ID
    useEffect(() => {
        if (isXxl) {
            setCols(5);
        } else if (isXl) {
            setCols(4);
        } else if (isLg) {
            setCols(2);
        } else if (isMd) {
            setCols(2);
        } else if (isSm) {
            setCols(1);
        } else {
            setCols(1);
        }
    }, [isXxl, isXl, isLg, isMd, isSm]);

    const [cardWidth, setCardWidth] = useState(0);

    useEffect(() => {
        function handleResize() {
            setCardWidth(window.innerWidth * 0.6 / cols);
        }

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [cols]);

    if (!data) return null; // when data isn't loaded yet

    return (
        <ImageList cols={cols} spacing={4}>
            {data.map(post => (
                <ImageListItem
                    key={post.id}
                >
                    <Link to={createPath({resource: 'posts', id: post.id, type: 'show'})}
                          style={{textDecoration: "none"}}
                    >
                        <Card className={classes.card}>
                            <CardHeader
                                avatar={
                                    <Avatar alt={post.creator.given_name}
                                            src={`${process.env.REACT_APP_CDN_URL}/${post.creator.avatar_thumbnail}`}
                                            sx={{width: 56, height: 56}} style={{marginRight: 4}}/>
                                }
                                title={post.creator.given_name + (post.creator.is_volunteer ? ` (${post.child.charity.charity_name})` : ``)}
                                subheader={moment(post.created_time).fromNow()}
                            />
                            {post.media_type === 'photo' && (
                                <CardMedia
                                    className={classes.media}
                                    style={{width: '100%', height: cardWidth * 1.1}}
                                    image={`${process.env.REACT_APP_CDN_URL}/${post.thumbnail}`}
                                    title={post.description}
                                />
                            )}
                            {post.media_type === 'video' && (
                                <ReactPlayer
                                    height={cardWidth * 1.1}
                                    width='100%'
                                    url={`${process.env.REACT_APP_CDN_URL}/${post.name}.${post.ext}`}
                                    light={`${process.env.REACT_APP_CDN_URL}/${post.thumbnail}`}
                                    controls={true}
                                />
                            )}
                            <CardContent className={classes.content}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "left",
                                        paddingBottom: 4
                                    }}
                                >
                                    <>
                                        <Avatar alt={post.child.name}
                                                src={`${process.env.REACT_APP_CDN_URL}/${post.child.profile_image_thumbnail}`}
                                                sx={{width: 24, height: 24}} style={{marginRight: 16}}/>
                                        {post.child.first_name} {post.child.last_name}
                                    </>
                                </div>
                                <Typography
                                    variant="h6"
                                    component="h6"
                                    underline="none"
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 1,
                                        WebkitBoxOrient: "vertical",
                                    }}
                                >
                                    {post.description}
                                </Typography>
                                <hr style={{border: "none", borderTop: "1px solid lightgray"}}/>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        paddingTop: 8,
                                    }}
                                >
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {post.nr_of_likes > 0 ? (
                                            <FavoriteIcon style={{color: "red", marginRight: 4}}/>
                                        ) : (
                                            <FavoriteBorderIcon style={{marginRight: 4}}/>
                                        )}
                                        <Typography variant="body2" component="span">
                                            {post.nr_of_likes}
                                        </Typography>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {post.nr_of_comments > 0 ? (
                                            <ChatBubbleIcon style={{color: "green", marginRight: 4}}/>
                                        ) : (
                                            <ChatBubbleOutlineIcon style={{marginRight: 4}}/>
                                        )}
                                        <Typography variant="body2" component="span">
                                            {post.nr_of_comments}
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Link>
                </ImageListItem>
            ))}
        </ImageList>
    );
};

const PostList = props => {
    const {permissions} = usePermissions();

    const {t} = useTranslation();

    const filters = [
        <TextInput label={t('Search')} source="q" alwaysOn/>,
    ];

    return (
        <List {...props} sort={{field: 'id', order: 'DESC'}} filters={filters}
              aside={hasPermission(permissions, ["default"], "charityFilter") ? <CharityFilterAside/> : null}>
            <FilterDisplay/>
            <PostGrid/>
        </List>
    )
}

export default PostList;
