import * as React from 'react';
import {Card, CardContent, CardHeader} from '@mui/material';
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {useTranslation} from "react-i18next";

const getPostsPerDay = (posts) => {
    let jsonData = [];
    for(let dateKey in posts){
        let singleRecord = {date: dateKey, total: posts[dateKey]};
        jsonData.push(singleRecord);
    }
    return jsonData;
};

const PostChart = ({ posts }) => {

    const { t } = useTranslation();

    if (!posts) return null;

    return (
        <Card>
            <CardHeader title={t('30 Day Post History')} />
            <CardContent>
                <div style={{ width: '100%', height: 500 }}>
                    <ResponsiveContainer>
                        <AreaChart width={730} height={250} data={getPostsPerDay(posts)}
                                   margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#f46d00" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#f46d00" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <XAxis
                                dataKey="date"
                                tick={{ fill: 'green' }}
                                tickFormatter={(date) => new Date(date).toLocaleDateString()}
                            />
                            <YAxis
                                tick={{ fill: '#f46d00' }}
                                label={{
                                    value: 'Number of Posts',
                                    angle: -90,
                                    position: 'insideLeft',
                                    fill: '#f46d00',
                                }}
                            />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Area type="monotone" dataKey="total" stroke="#f46d00" fillOpacity={1} fill="url(#colorUv)" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

export default PostChart;
