import React, {useState} from 'react';
import {usePermissions, useRecordContext} from 'react-admin';
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import {axiosApiInstance} from "../AxiosUtils";
import hasPermission from "../auth/hasPermission";

export const MailSettingsAside = () => {

    // We want to be able to translate the admin
    const {t} = useTranslation();

    // Template values to use
    // eslint-disable-next-line no-template-curly-in-string
    const projectName = "${projectName}";
    // eslint-disable-next-line no-template-curly-in-string
    const givenName = "${givenName}";
    // eslint-disable-next-line no-template-curly-in-string
    const familyName = "${familyName}";
    // eslint-disable-next-line no-template-curly-in-string
    const childName = "${childName}";
    // eslint-disable-next-line no-template-curly-in-string
    const addressInfo = "${addressInfo}";
    // eslint-disable-next-line no-template-curly-in-string
    const iban = "${iban}";
    // eslint-disable-next-line no-template-curly-in-string
    const salutation = "${salutation}";
    // eslint-disable-next-line no-template-curly-in-string
    const email = "${email}";
    // eslint-disable-next-line no-template-curly-in-string
    const phone = "${phone}";

    // We need access to the record from the functions
    const record = useRecordContext();

    // What permissions does the current user have?
    const {permissions} = usePermissions();

    const [mailAddress, setMailAddress] = useState("");
    const [newSponsor, setNewSponsor] = useState(true);
    const [ibanDonationMethod, setIbanDonationMethod] = useState(true);
    const [mailType, setMailType] = useState("registration");
    const [successAlert, setSuccessAlert] = useState(false);
    const [warningAlert, setWarningAlert] = useState(false);
    const [sending, setSending] = useState(false);

    const sendTestMail = () => {
        setSending(true);
        setSuccessAlert(false);
        setWarningAlert(false);
        if (mailAddress) {
            const rolePath = (permissions === 'admin' ? 'admin' : 'umbrellad');
            let postFix = '';
            if( hasPermission(permissions, ["mailsettings"], "umbrella") ) {
                postFix = `${record.charity_id}`;
            }
            let formData = new FormData();
            formData.append("email", mailAddress);
            formData.append("new_sponsor", newSponsor ? "true" : "false");
            formData.append("iban_donation_method", ibanDonationMethod ? "true" : "false");
            formData.append("code", record.language_code);
            axiosApiInstance.post(
                `${process.env.REACT_APP_API_URL}/api/v1/${rolePath}/sendTestMail/${mailType}/${postFix}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                }
            ).then((response) => {
                if (response.status === 200) {
                    setSending(false);
                    setSuccessAlert(true);
                    setWarningAlert(false);
                }
            }).catch((reason) => {
                setSending(false);
                console.log(JSON.stringify(reason));
            })
        } else {
            setSending(false);
            setWarningAlert(true);
            setSuccessAlert(false);
        }
    }

    const handleSponsorChange = (e) => {
        setNewSponsor(e.target.checked);
    };

    const handleIbanChange = (e) => {
        setIbanDonationMethod(e.target.checked);
    };

    const handleMailTypeChange = (event) => {
        setMailType((event.target).value);
    };

    return (
        <form>
        <Box sx={{width: '300px', margin: '1em'}}>
            <Typography>
                <strong>Let op:</strong>
                <br/>De tekst om de Nofam app te downloaden wordt standaard met elke e-mail meegestuurd indien het een
                nieuwe sponsor betreft.
                Dit daar hier de links vast staan en ook het gegenereerde tijdelijke wachtwoord.
                <br/>De tekst IBAN of anders wordt getoond afhankelijk van de keuze van de sponsor (IBAN of anders).
            </Typography>
            <br/><br/>
            <Typography sx={{ color: '#000000' }}>
                <strong>(Optioneel) Te gebruiken velden van de sponsor:</strong>
                <br/>Aanhef: {salutation}
                <br/>Voornaam: {givenName}
                <br/>Achternaam: {familyName}
                <br/>E-mailadres: {email}
                <br/>Telefoonnummer: {phone}
                <br/>Adresgegevens: {addressInfo}
                <br/>IBAN-nummer: {iban}
                <br/>Naam sponsorkind: {childName}
                <br/>Naam van het project: {projectName}
            </Typography>
            <br/>
            <Typography>
                <strong>Verstuur een testmail:</strong>
            </Typography>
            <br/>
            <TextField id="email" label="E-mailadres" variant="outlined"
                       onChange={(e) => setMailAddress(e.target.value)}/>
            <br/>
            <FormLabel id="demo-row-radio-buttons-group-label">Mail type</FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={mailType}
                onChange={handleMailTypeChange}
            >
                <FormControlLabel value="registration" control={<Radio />} label={t("Registration mail")} />
                <FormControlLabel value="update" control={<Radio />} label={t("Reminder for new posts")} />
                <FormControlLabel value="new" control={<Radio />} label={t("Daily new post mail")} />
            </RadioGroup>
            <FormControlLabel
                control={<Checkbox checked={newSponsor} onChange={handleSponsorChange} />}
                label="Nieuwe sponsor"/>
            <br/>
            <FormControlLabel
                control={<Checkbox checked={ibanDonationMethod} onChange={handleIbanChange} />}
                label="IBAN donatiemethode"/>
            <br/>
            <LoadingButton
                onClick={sendTestMail}
                loading={sending}
                variant="contained">{t('Send test mail')}</LoadingButton>

            {successAlert ? <Alert severity="success">
                <AlertTitle>Succes</AlertTitle>
                We hebben een mail verstuurd
            </Alert> : <></>}

            {warningAlert ? <Alert severity="warning">
                <AlertTitle>Warning</AlertTitle>
                E-mailadres is nog niet ingevuld
            </Alert> : <></>}
        </Box>
        </form>
    );
}
