import React from 'react';
import {
    Datagrid,
    DateField,
    downloadCSV,
    FunctionField,
    List,
    NullableBooleanInput, SelectInput,
    TextField,
    TextInput,
    usePermissions
} from 'react-admin';
import {CharityFilterAside} from "../filter/CharityFilterAside";
import {useStyles} from "../styles";
import {useTranslation} from 'react-i18next';
import {Avatar, SvgIcon} from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/EscalatorWarning";
import hasPermission from "../auth/hasPermission";
import jsonExport from 'jsonexport/dist';

const GuardianList = (props) => {
    const classes = useStyles();
    const {permissions} = usePermissions();

    const {t} = useTranslation();

    const exporter = dataset => {
        const dataForExport = dataset.map(item => {
            const {
                backlinks, _links, avatar, avatar_thumbnail, initials,
                children, ...dataForExport
            } = item; // omit backlinks and author
            return dataForExport;
        });
        if(hasPermission(permissions, ["export_details"], "guardians")) {
            jsonExport(dataForExport, {
                headers: ['id', 'salutation', 'given_name', 'family_name', 'email', 'street', 'house_number', 'postal_code', 'city', 'country',
                    'sponsor_as_company', 'company', 'donation_method', 'iban', 'direct_debit_reference', 'direct_debit_start_date',
                    'phone', 'sponsor_children', 'last_access_date_time', 'create_date_time', 'enabled'],// order fields in the export
                rename: ['ID', t('Salutation'), t('First name'), t('Last name'), t('email'), t('Street'), t('House number'), t('Postal code'), t('City'), t('Country'),
                    t('Sponsor as company'), t('Company'), t('Donation method'), "IBAN", t('Direct debit reference'), t('Direct debit start date'),
                    t('Phone'), t('Guardian of'), t('Last access date'), t('Registration date'), t('Enabled')]
            }, (err, csv) => {
                downloadCSV(csv, 'sponsoren'); // download as 'posts.csv` file
            });
        } else {
            jsonExport(dataForExport, {
                headers: ['salutation', 'given_name', 'family_name', 'email', 'city', 'country', 'phone', 'sponsor_children', 'last_access_date_time', 'create_date_time', 'enabled'],// order fields in the export
                rename: [t('Salutation'), t('First name'), t('Last name'), t('email'), t('City'), t('Country'), t('Phone'), t('Guardian of'), t('Last access date'), t('Registration date'), t('Enabled')]
            }, (err, csv) => {
                downloadCSV(csv, 'sponsoren'); // download as 'posts.csv` file
            });
        }
    };

    const filters = [
        <TextInput label={t('Search')} source="q" alwaysOn/>,
        <NullableBooleanInput
            label={t('Enabled')}
            source="enabled"
            nullLabel={t('All')}
            falseLabel={t('No')}
            trueLabel={t('Yes')}
            alwaysOn
        />,
        <SelectInput
            alwaysOn
            allowEmpty
            label={t('Donation method')}
            source="donation_method"
            choices={[
                { id: 'iban', name: 'IBAN' },
                { id: 'other', name: 'Anders' },
            ]}
        />,
    ];

    return (
        <List {...props} title={t('Guardians')} sort={{field: 'create_date_time', order: 'DESC'}} perPage={50}
              exporter={exporter} filters={filters} filterDefaultValues={{ enabled: true }}
              aside={hasPermission(permissions, ["default"], "charityFilter") ? <CharityFilterAside/> : null}>
            <Datagrid bulkActionButtons={false} rowClick="show" className={classes.root}>
                <FunctionField render={record =>
                    <Avatar sx={{height: 40, width: 40}}
                            src={`${process.env.REACT_APP_CDN_URL}/${record.avatar}`}/>}/>
                />
                <FunctionField render={((record) => {
                    if (record.sponsor_children && record.sponsor_children.length > 0) {
                        return (<SvgIcon style={{fontSize: 24}}>
                            <AdminPanelSettingsIcon sx={{color: "#84ba3f"}}/>
                        </SvgIcon>)
                    }
                })}/>
                <FunctionField
                    label={t('Salutation')}
                    render={(record) => t(record.salutation)}
                    sortable={false}
                />
                <TextField source="given_name" label={t('First name')}/>
                <TextField source="family_name" label={t('Last name')}/>
                <DateField source="create_date_time" label={t('Registration date')}/>
                <TextField source="city" label={t('City')} sortable={false}/>
                <TextField source="country" label={t('Country')} sortable={false}/>
                <DateField source="last_access_date_time" label={t('Last access date')} options={{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                }}/>
                {hasPermission(permissions, ["create", "edit"], "guardians") &&
                    <TextField source="direct_debit_reference" label={t('Direct debit reference')} sortable={false}/>
                }
            </Datagrid>
        </List>
    );
}

export default GuardianList;
