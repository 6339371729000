import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import {useTranslation} from "react-i18next";

const MAX_LENGTH = 300;

export default function CommentInput({ onSubmit, commentInputRef, commentId }) {

    const {t} = useTranslation();

    const [value, setValue] = useState('');

    const handleChange = (event) => {
        setValue(event.target.value.slice(0, MAX_LENGTH));
    };

    const handleSubmit = () => {
        onSubmit(value, commentId);
        setValue('');
    };

    return (
        <div>
            <TextField
                fullWidth
                multiline
                maxRows={5}
                value={value}
                onChange={handleChange}
                label={t('Leave a comment')}
                variant="outlined"
                inputProps={{ maxLength: MAX_LENGTH }}
                style={{ marginRight: '16px' }}
                inputRef={commentInputRef}
            />
            <Button
                variant="contained"
                color="primary"
                disabled={!value}
                onClick={handleSubmit}
            >
                Post
            </Button>
        </div>
    );
};
