import React from 'react';
import {Show, TabbedShowLayout, TextField} from 'react-admin';
import {useTranslation} from "react-i18next";

const UserActionLogsShow = (props) => {
    const {t} = useTranslation();

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label={t('Details')}>
                    <TextField source="id"/>
                    <TextField source="nofam_user_id"/>
                    <TextField source="platform"/>
                    <TextField source="endpoint"/>
                    <TextField source="method"/>
                    <TextField source="action"/>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>

        </Show>
    );
}

export default UserActionLogsShow;
