import React from 'react';
import {Datagrid, List, TextField} from 'react-admin';
import {useStyles} from "../styles";
import {useTranslation} from "react-i18next";

const UserActionLogsList = (props) => {
    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <List {...props} title={t('User Action Logs')} sort={{field: 'id', order: 'DESC'}}  perPage={50}>
            <Datagrid bulkActionButtons={false} rowClick="show" className={classes.root}>
                <TextField source="date"/>
                <TextField source="nofam_user_id"/>
                <TextField source="platform"/>
                <TextField source="endpoint"/>
                <TextField source="method"/>
                <TextField source="action"/>
            </Datagrid>
        </List>
    );
}

export default UserActionLogsList;
