import React from 'react';
import {BooleanField, Datagrid, DateField, FunctionField, List, TextField, TextInput} from 'react-admin';
import {CharityFilterAside} from "../filter/CharityFilterAside";
import {useStyles} from "../styles";
import {useTranslation} from "react-i18next";
import {Avatar} from "@mui/material";

const VolunteerList = (props) => {
    const classes = useStyles();

    const {t} = useTranslation();

    const filters = [
        <TextInput label={t('Search')} source="q" alwaysOn/>,
    ];

    return (
        <List {...props} title={t('Volunteers')} sort={{field: 'create_date_time', order: 'DESC'}} perPage={50}
              filters={filters}
              aside={<CharityFilterAside/>}>
            <Datagrid bulkActionButtons={false} rowClick="show" className={classes.root}>
                <FunctionField render={record =>
                    <Avatar sx={{height: 40, width: 40}}
                            src={`${process.env.REACT_APP_CDN_URL}/${record.avatar}`}/>}/>
                />
                <TextField source="email" label={t('Email')}/>
                <TextField source="given_name" label={t('First name')}/>
                <TextField source="family_name" label={t('Last name')}/>
                <TextField source="charity_model.charity_name" label={t('Charity')} sortable={false}/>
                <DateField source="create_date_time" label={t('Registration date')}/>
                <DateField source="last_access_date_time" label={t('Last access date')} options={{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                }}/>
                <BooleanField source="enabled" label={t('Enabled')}/>
            </Datagrid>
        </List>
    );
}

export default VolunteerList;
