import * as React from 'react';
import {createElement} from 'react';
import {Box, Card, Divider, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import theme from "../themes";

import cartouche from '../cartouche.png';
import cartoucheDark from '../cartoucheDark.png';

const CardWithIcon = (props) => {
    const {icon, title, subtitle, to, children} = props;

    return (
        // @ts-ignore
        <Card
            sx={{
                minHeight: 52,
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                },
            }}
        >
            <Link to={to}>
                <Box
                    sx={{
                        overflow: 'inherit',
                        padding: '16px',
                        background: theme =>
                            `url(${
                                theme.palette.mode === 'dark'
                                    ? cartoucheDark
                                    : cartouche
                            }) no-repeat`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box width="3em" className="icon">
                        {createElement(icon, {fontSize: 'large'})}
                    </Box>
                    <Box textAlign="right">
                        <Typography color={theme.palette.primary.main}>{title}</Typography>
                        <Typography variant="h5" component="h2">
                            {subtitle || ' '}
                        </Typography>
                    </Box>
                </Box>
            </Link>
            {children && <Divider/>}
            {children}
        </Card>
    );
};

export default CardWithIcon;
