import {useRecordContext} from "react-admin";
import {Box, Typography} from "@mui/material";
import React from "react";

export default function CustomLabelField({source, label}) {
    const record = useRecordContext();

    return (
        <Box sx={{marginBottom: 2}}>
            <Typography variant="caption" display="block" gutterBottom>
                {label}
            </Typography>
            {record[source]}
        </Box>
    );
};
