import {useEffect, useState} from 'react';
import {usePermissions, useGetList} from "react-admin";
import {axiosApiInstance} from "../AxiosUtils";
import hasPermission from "../auth/hasPermission";

export const useCharityChoices = () => {
    const [charityChoices, setCharityChoices] = useState([]);

    // We need to check the permissions of the users
    const {permissions} = usePermissions();

    const {data: charities} = useGetList('charities', {
        pagination: { page: 1, perPage: 50 },
        sort: {field: 'charityName', order: 'ASC'},
    });

    useEffect(() => {
        if (charities && hasPermission(permissions, ["list"], "charityChoices") ) {
            const myCharityChoices = charities.map(charity => ({
                id: charity.id,
                name: charity.charity_name,
                active: charity.active
            }));
            setCharityChoices(myCharityChoices);
        }
    }, [charities, permissions]);

    return charityChoices;
};

export const useUmbrellaChoices = () => {
    const [umbrellaChoices, setUmbrellaChoices] = useState([]);

    // We need to check the permissions of the users
    const {permissions} = usePermissions();

    useEffect(() => {
        if ( hasPermission(permissions, ["list"], "umbrella") ) {
            const fetchUmbrellas = async () => {
                // Only admin users need the umbrella filter
                    await axiosApiInstance.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/umbrellas?size=50`).then((response) => {
                        const umbrellas = response.data._embedded.charities;
                        let myCharityChoices = [];
                        for (let i = 0; i < umbrellas.length; i++) {
                            let umbrella = umbrellas[i];
                            let jsonOjbect = {name: umbrella.charity_name, id: umbrella.id, active: umbrella.active}
                            myCharityChoices.push(jsonOjbect);
                        }
                        setUmbrellaChoices(myCharityChoices);
                    });
            };

            fetchUmbrellas().then(r => {
            });
        }
    }, [permissions]);

    return umbrellaChoices;
};
