import {useRecordContext, BooleanField} from "react-admin";
import {Box, Typography, Checkbox, FormControlLabel} from "@mui/material";
import React from "react";

export default function CustomBooleanField({source, label}) {
    const record = useRecordContext();

    return (
        <Box sx={{marginBottom: 2}}>
            <FormControlLabel
                control={
                    <Checkbox checked={record[source]} name={label} color="success" />
                }
                label={label}
            />
        </Box>
    );
};
