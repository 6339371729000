import React, {useState} from 'react';
import {BooleanField, DateField, Show, usePermissions, useRecordContext, WithRecord} from 'react-admin';
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {Avatar, Box, Button, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import hasPermission from "../auth/hasPermission";
import "../App.css";
import CustomTextField from "../components/CustomTextField";
import CustomLabelField from "../components/CustomLabelField";
import {axiosApiInstance} from "../AxiosUtils";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";

const GuardianData = (props) => {

    const {t} = useTranslation();

    const {permissions} = usePermissions();

    const record = useRecordContext();

    const [resetting, setResetting] = useState(false);
    const [successMessage, showSuccessMessage] = useState(false);
    const [failureMessage, showFailureMessage] = useState(false);

    const getSponsoredChildren = () => {
        if (record && record.children && record.children.length > 0) {
            return record.children.map((child, i) => {
                return <div style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    padding: 16
                }} key={i}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight: 16}}>
                        <Avatar alt={child.name}
                                src={`${process.env.REACT_APP_CDN_URL}/${child.profile_image}.${child.image_ext}`}
                                sx={{width: 56, height: 56}} style={{marginRight: 16}}/>
                        <Link to={`/children/${child.id}/show`}>
                            <Typography component="h6" variant="h6">{child.first_name} {child.last_name}</Typography>
                        </Link>
                    </div>
                </div>
            })
        }
    }

    const resetPassword = () => {
        if (record) {
            const rolePath = (permissions === 'admin' ? 'admin' : 'umbrellad');
            setResetting(true);
            showSuccessMessage(false);
            showFailureMessage(false);
            axiosApiInstance.post(
                `${process.env.REACT_APP_API_URL}/api/v1/${rolePath}/guardians/${record.id}/resetPassword`
            ).then((response) => {
                setResetting(false);
                showSuccessMessage(true);
            }).catch((reason) => {
                console.log(JSON.stringify(reason));
                setResetting(false);
                showFailureMessage(true);
            })
        }
    }

    if (!record) return null;

    return (
        <div class="showie">
            <Grid container spacing={4} sx={{padding: '8px'}} xs={12} lg={12}>
                <Grid item xs={12}>
                    <div class="custom-box borderGreen">
                        <WithRecord
                            render={record =>
                                <div class="rowit">
                                    <Avatar sx={{height: 100, width: 100, marginRight: '16px'}}
                                            src={`${process.env.REACT_APP_CDN_URL}/${record.avatar}`}/>
                                    <div>
                                        <Typography sx={{fontWeight: 700, fontSize: '24px'}}>
                                            {record.salutation ? t(record.salutation) : ''} {record.given_name} {record.family_name}
                                        </Typography>
                                        {record.relation_number && record.relation_number !== '' && record.relation_number !== null &&
                                            <Typography sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                fontStyle: 'italic',
                                            }}>({t('Relation number')} : {record.relation_number})</Typography>
                                        }
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div class="custom-box">
                        <Typography variant="h6" gutterBottom>{t('General info')}</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <div>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {t('Registration date')}
                                    </Typography>
                                    <DateField source="create_date_time" options={{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    }}/>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {t('Enabled')}
                                    </Typography>
                                    <BooleanField source="enabled"/>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {t('Last access date')}
                                    </Typography>
                                    <DateField source="last_access_date_time" options={{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    }}/>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div class="custom-box" style={{marginTop: '24px'}}>
                        <Typography variant="h6" gutterBottom>{t('Contact details')}</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <CustomTextField source="email" label={t('Email')} sx={{marginRight: '6px'}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField source="phone" label={t('Phone')}/>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomLabelField source="firebase_status"
                                                  label={t('Notification registration status')}/>
                            </Grid>
                            <Grid item xs={6}>
                                <LoadingButton
                                    onClick={resetPassword}
                                    loading={resetting}
                                    variant="outlined"
                                    sx={{ml: 2}}
                                >{t('Reset password')}</LoadingButton>
                                <div style={{marginTop: '8px'}}>
                                    {successMessage ? <Alert severity="success">
                                        {t('Password reset mail sent')}
                                    </Alert> : <></>}

                                    {failureMessage ? <Alert severity="warning">
                                        {t('Could not send password reset mail')}
                                    </Alert> : <></>}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="custom-box" style={{marginTop: '24px'}}>
                        <Typography variant="h6" gutterBottom>{t('Address')}</Typography>
                        {hasPermission(permissions, ["export_details"], "guardians") &&
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <CustomTextField source="street" label={t('Street')} sx={{marginRight: '6px'}}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextField source="house_number" label={t('House number')}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <CustomTextField source="postal_code" label={t('Postal code')}
                                                     sx={{marginRight: '6px'}}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <CustomTextField source="city" label={t('City')} sx={{marginRight: '6px'}}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <CustomTextField source="country" label={t('Country')}/>
                                </Grid>
                            </Grid>
                        }
                        {!hasPermission(permissions, ["export_details"], "guardians") &&
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <CustomTextField fullWidth source="city" label={t('City')}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <CustomTextField fullWidth source="country" label={t('Country')}/>
                                </Grid>
                            </Grid>
                        }
                    </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div class="custom-box">
                        <Typography variant="h6" gutterBottom>{t('Sponsor info')}</Typography>
                        <Grid container spacing={2} lg={12}>
                            <Grid item lg={6}>
                                <Typography style={{fontWeight: 500, fontSize: 12}}>{t('Guardian of')}</Typography>
                                {getSponsoredChildren()}
                            </Grid>
                            <Grid item lg={6}>
                                <WithRecord
                                    render={record =>
                                        <Box mt={2}>
                                            <Typography style={{fontWeight: 500, fontSize: 12}}
                                                        gutterBottom>{t('Posts')}</Typography>
                                            <Link to={`/posts?filter={"user_id":"${record.id}"}`}>
                                                <Button
                                                    variant="contained">{t(`View posts for`)} {record.given_name}</Button>
                                            </Link>
                                        </Box>
                                    }/>
                            </Grid>
                        </Grid>
                    </div>
                    {hasPermission(permissions, ["export_details"], "guardians") &&
                        <>
                            <div class="custom-box" style={{marginTop: '24px'}}>
                                <Typography variant="h6" gutterBottom>{t('Sponsor payment info')}</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <CustomTextField source="donation_method" label={t('Donation method')}
                                                         sx={{marginRight: '6px'}}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomTextField source="iban" label={t('IBAN')}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <CustomTextField source="direct_debit_reference"
                                                         label={t('Direct debit reference')}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                {t('Direct debit start date')}
                                            </Typography>
                                            <DateField source="direct_debit_start_date" options={{
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                            }}/>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="caption" display="block" gutterBottom>
                                            {t('Sponsor as company')}
                                        </Typography>
                                        <BooleanField source="sponsor_as_company"/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomTextField source="company" label={t('Company')}/>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="custom-box" style={{marginTop: '24px'}}>
                                <Typography variant="h6" gutterBottom>{t('Other info')}</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <CustomTextField source="note" label={t('Note')}
                                                         sx={{marginRight: '6px'}} multiline={true}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    }

                </Grid>
            </Grid>
        </div>
    );
}


const GuardianShow = (props) => {
    return (
        <Show {...props} className="showie">
            <GuardianData/>
        </Show>
    )
};

export default GuardianShow;
