import React from 'react';
import {Avatar} from '@mui/material';
import {
    BooleanField,
    Datagrid,
    DateField,
    FunctionField,
    List,
    TextField,
    TextInput,
    usePermissions,
    downloadCSV,
    NullableBooleanInput
} from 'react-admin';
import {CharityFilterAside} from "../filter/CharityFilterAside";
import {useStyles} from "../styles";
import {FalseIcon, TrueIcon} from "../icons/icons";
import {useTranslation} from "react-i18next";
import hasPermission from "../auth/hasPermission";
import jsonExport from 'jsonexport/dist';

const ChildList = props => {
    const classes = useStyles();
    const {permissions} = usePermissions();

    const {t} = useTranslation();

    const exporter = children => {
        const childrenForExport = children.map(child => {
            const { backlinks, profile_image_thumbnail, profile_video_thumbnail, nr_of_guardians, _links,
                country, location, description, family, help, profile_video, video_ext,
                guardians, translations, ...childrenForExport } = child; // omit backlinks and author
            return childrenForExport;
        });
        jsonExport(childrenForExport, {
            headers: ['id', 'first_name', 'last_name'] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'kinderen'); // download as 'posts.csv` file
        });
    };

    const filters = [
        <TextInput label={t('Search')} source="q" alwaysOn />,
        <NullableBooleanInput
            label={t('Verified')}
            source="verified"
            nullLabel={t('All')}
            falseLabel={t('No')}
            trueLabel={t('Yes')}
            alwaysOn
        />,
        <NullableBooleanInput
            label={t('Sponsored')}
            source="child_is_sponsored"
            nullLabel={t('All')}
            falseLabel={t('No')}
            trueLabel={t('Yes')}
            alwaysOn
        />
    ];

    return (
        <List {...props} sort={{field: 'id', order: 'DESC'}} perPage={25} exporter={exporter} filters={filters}
              aside={hasPermission(permissions, ["default"], "charityFilter") ? <CharityFilterAside/> : null}>
            <Datagrid bulkActionButtons={false} rowClick="show" className={classes.root}>
                <FunctionField render={record =>
                    <Avatar sx={{height: 40, width: 40}}
                            src={`${process.env.REACT_APP_CDN_URL}/${record.profile_image_thumbnail}`}/>}/>
                />
                <TextField source="first_name" label={t('First name')}/>
                <TextField source="last_name" label={t('Last name')}/>
                <BooleanField label={t('Verified')} source="verified" TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>
                <BooleanField label={t('Sponsored')} source="child_is_sponsored" TrueIcon={TrueIcon}
                              FalseIcon={FalseIcon}/>
                {hasPermission(permissions, ["*"], "children.charity_name") &&
                    <TextField source="charity_name" label={t('Charity')} sortable={false}/>
                }
                <DateField source="birth_date" label={t('Birthdate')}/>
                <DateField source="create_date_time" label={t('Reg date')}/>
            </Datagrid>
        </List>
    );
}

export default ChildList;
