import {useEffect, useState} from "react";
import {axiosApiInstance} from "../AxiosUtils";
import {usePermissions} from "react-admin";
import hasPermission from "../auth/hasPermission";

const useUmbrellasAndCharities = ({umbrellaId = 0}) => {

    // We need to check the permissions of the users
    const {permissions} = usePermissions();

    const [umbrellas, setUmbrellas] = useState(null);
    const [charities, setCharities] = useState(null);

    const rolePath = (permissions === 'admin' ? 'admin' : 'umbrellad');
    const urlPrefix = `${process.env.REACT_APP_API_URL}/api/v1/${rolePath}`;

    useEffect(() => {
        const fetchCharities = async () => {
            if (hasPermission(permissions, ["view"], "charities")) {
                const umbrellaIdPostFix = umbrellaId ? `?umbrella_id=${umbrellaId}&size=50&sort=charityName,ASC` : `?size=50&sort=charityName,ASC`;
                await axiosApiInstance.get(`${urlPrefix}/charities${umbrellaIdPostFix}`).then((response) => {
                    if (response.data._embedded && response.data._embedded.charities) {
                        setCharities(response.data._embedded.charities);
                    } else {
                        setCharities([]);
                    }
                });
            }
        };

        fetchCharities().then(r => {
        });

    }, [umbrellaId, urlPrefix]);

    useEffect(() => {
        const fetchUmbrellas = async () => {
            // Only admin users need the umbrella filter
            if (hasPermission(permissions, ["view"], "umbrella")) {
                await axiosApiInstance.get(`${urlPrefix}/umbrellas?size=50&sort=charityName,ASC`).then((response) => {
                    setUmbrellas(response.data._embedded.charities);
                });
            }
        };

        fetchUmbrellas().then(r => {
        });

    }, [permissions, urlPrefix]);

    return {umbrellas, charities};
};

export default useUmbrellasAndCharities;
