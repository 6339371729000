import React from 'react';
import {Datagrid, DateField, List, TextField} from 'react-admin';
import {CharityFilterAside} from "../filter/CharityFilterAside";
import {useStyles} from "../styles";
import {useTranslation} from "react-i18next";

const ChildUpdatePostStatsList = (props) => {
    const classes = useStyles();

    const {t} = useTranslation();

    const postRowStyle = (record, index) => ({
        backgroundColor: index % 2 === 0 ? '#faf9f9' : 'white',
    });

    return (
        <List {...props} title={t('Child Update Post Statistics')} perPage={50} aside={<CharityFilterAside/>}>
            <Datagrid bulkActionButtons={false} rowClick="show" className={classes.root} rowSx={postRowStyle}>
                <TextField source="child_name" label={t('Child name')}/>
                <TextField source="guardian_name" label={t('Guardian name')}/>
                <DateField source="last_charity_post_date" label={t('Last charity post')}/>
                <TextField source="nr_of_charity_posts" label={t('# of charity posts')}/>
                <DateField source="last_guardian_post_date" label={t('Last guardian post')}/>
                <TextField source="nr_of_guardian_posts" label={t('# of guardian posts')}/>
                <TextField source="nr_of_guardian_likes" label={t('# likes')}/>
                <TextField source="nr_of_guardian_comments" label={t('# comments')}/>
                <DateField source="last_access_date_time" label={t('Last access date')} options={{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                }}/>
            </Datagrid>
        </List>
    );
}

export default ChildUpdatePostStatsList;
