import React from 'react';
import {Show, SimpleShowLayout, TabbedShowLayout, TextField, WithRecord} from 'react-admin';
import {useTranslation} from "react-i18next";
import {Avatar} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    errorBox: {
        backgroundColor: '#ffffff',
        color: '#000000',
        padding: '1em',
    },
});

const AppErrorsShow = (props) => {
    const {t} = useTranslation();

    const classes = useStyles();

    return (
        <Show {...props}>
            <SimpleShowLayout sx={{backgroundColor: "#84ba3f", color: "#FFF"}}>
                <WithRecord render={record =>
                    <div style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        padding: 16
                    }}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight: 16}}>
                            <Avatar alt={`${record.user.given_name} ${record.user.family_name}`}
                                    src={`${process.env.REACT_APP_CDN_URL}/${record.user.avatar}`}
                                    sx={{width: 40, height: 40}} style={{marginRight: 16}}/>
                            <Link
                                to={record.user.is_volunteer ? `/volunteers/${record.user.id}/show` : `/guardians/${record.user.id}/show`}>
                                <div style={{
                                    fontSize: 16,
                                    color: '#FFFFFF'
                                }}>{record.user.given_name} {record.user.family_name}</div>
                            </Link>
                            &nbsp; registered the following error at&nbsp;
                            <div style={{fontSize: 16, color: '#FFFFFF'}}>{record.create_date_time}</div>
                        </div>
                    </div>
                }/>
            </SimpleShowLayout>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label={t('Details')}>
                    <TextField source="platform"/>
                    <TextField source="screen"/>
                    <TextField source="action"/>
                    <WithRecord
                        render={record =>
                            <div className={classes.errorBox}>
                                <h3>Error</h3>
                                <pre>{JSON.stringify(JSON.parse(record.message), null, 2)}</pre>
                            </div>
                        }/>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>

        </Show>
    );
}

export default AppErrorsShow;
