import * as React from 'react';
import { Box, Button, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { useGetList } from 'react-admin';
import { subDays } from 'date-fns';
import { useTranslation } from "react-i18next";

const NewPosts = ({umbrellaId, charityId}) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const aMonthAgo = subDays(new Date(), 30);
    aMonthAgo.setDate(aMonthAgo.getDate() - 30);
    aMonthAgo.setHours(0);
    aMonthAgo.setMinutes(0);
    aMonthAgo.setSeconds(0);
    aMonthAgo.setMilliseconds(0);

    const {data: posts} = useGetList(
        'posts',
        {
            sort: {field: 'id', order: 'DESC'},
            pagination: {page: 0, perPage: 12},
            filter: {charity_id: charityId, umbrella_id : umbrellaId}
        },
    );

    if (!posts) return null;

    return (
        <div>
            <div>{t('Last posts')}</div>
            <hr/>
            <ImageList cols={6} rowHeight={350} className={classes.gridList}>
                {posts.map((post) => (
                    <ImageListItem key={post.id} component={Link} to={`/posts/${post.id}/show`} className={classes.imageListItem}>
                        <img src={`${process.env.REACT_APP_CDN_URL}/${post.thumbnail}`} alt={post.description} style={{objectFit: "cover", width: "100%", height: "100%", borderRadius: 9, borderWidth: 1}} />
                        <ImageListItemBar title={post.description} className={classes.itemBar} />
                    </ImageListItem>
                ))}
            </ImageList>
            <Box flexGrow="1">&nbsp;</Box>
            <Button
                className={classes.link}
                component={Link}
                to="/posts"
                size="small"
                color="primary"
            >
                <Box p={1} className={classes.linkContent}>
                    {t('Show all posts')}
                </Box>
            </Button>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: '100%',
        height: 'auto',
    },
    imageListItem: {
        height: "350px",
    },
    itemBar: {
        color: '#FFF',
        borderBottomLeftRadius: 9,
        borderBottomRightRadius: 9,
    },
    textOverflow: {
        width: '100%',
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));


export default NewPosts;
