import React from 'react';
import {BooleanField, Datagrid, List, TextField} from 'react-admin';
import {useStyles} from "../styles";
import {useTranslation} from "react-i18next";
import {SmallTrueIcon, SmallFalseIcon} from "../icons/icons";

const MailLogsList = (props) => {
    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <List {...props} title={t('User Action Logs')} sort={{field: 'id', order: 'DESC'}} perPage={50}>
            <Datagrid bulkActionButtons={false} rowClick="show" className={classes.root}>
                <TextField source="sent_date_time" label={t('Date sent')}/>
                <TextField source="mail_type" label="Mail type"/>
                <TextField source="recipient" label={t('Guardian name')}/>
                <TextField source="last_access_date_time" label={t('Last access')} sortable={false}/>
                <TextField source="charity" label={t('Charity')} sortable={false}/>
                <TextField source="child" label={t('Child name')}/>
                <TextField source="success" label={t('Sent successfully')}/>
                <BooleanField source="read" label={t('Read')} TrueIcon={SmallTrueIcon} FalseIcon={SmallFalseIcon}/>
                <BooleanField source="clicked" label={t('Clicked')} TrueIcon={SmallTrueIcon} FalseIcon={SmallFalseIcon}/>
            </Datagrid>
        </List>
    );
}

export default MailLogsList;
