import React, {useEffect, useState} from 'react';
import {
    BooleanInput,
    Create,
    Edit,
    FileField,
    FileInput,
    FormDataConsumer, FunctionField,
    ImageField,
    ImageInput,
    RadioButtonGroupInput,
    required,
    SimpleForm,
    TextInput,
    usePermissions,
    useRecordContext
} from 'react-admin';
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {useLocation} from "react-router-dom";
import {useCharityChoices} from "../lib/customHooks";
import {Avatar, Typography} from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ReactPlayer from "react-player";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const PostFormFields = ({create}) => {
    // We need to check the permissions of the users
    const {permissions} = usePermissions();

    const record = useRecordContext();

    const [child, setChild] = useState(null);

    const location = useLocation();

    // We want to be able to translate the admin
    const {t} = useTranslation();

    // We'll want to access the theme
    const theme = useTheme();

    // A volunteer belongs to a specific charity, retrieve the options here
    const charityChoices = useCharityChoices();

    useEffect(() => {
        const childData = location.state?.child;
        if (childData) {
            setChild(childData);
        } else {
            if (record && record.child) {
                setChild(record.child);
            }
        }
    }, [location, record]);

    // We need the choices before we can continue
    if (!charityChoices) return null;

    return (
        <SimpleForm redirect="show">
            {create && !child &&
                <Alert severity="warning" sx={{ mb: 2 }}>
                    <AlertTitle>{t('Attention')}</AlertTitle>
                    {t('First select a child for new update post')}
                </Alert>
            }
            {child &&
                <>
                    <Typography sx={{fontWeight: 'bold', fontSize: 18, mt: 2}}>
                        {create && t('Share new update post for')}
                        {!create && t('Post shared for')}
                    </Typography>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 16
                    }}>
                        <div style={{display: "flex", flexDirection: "row", marginRight: 16}}>
                            <Avatar alt={child.name}
                                    src={`${process.env.REACT_APP_CDN_URL}/${child.profile_image}.${child.image_ext}`}
                                    sx={{width: 56, height: 56}}/>
                            <Typography>{child.name}</Typography>
                        </div>
                    </div>
                    <TextInput label="Child ID" source="child_id" defaultValue={child ? child.id : ''} disabled />
                </>
            }
            {child &&
                <TextInput multiline fullWidth label={t('Description')} source="description" validate={required()} numberOfLines={4}/>
            }

            {create && child &&
                <>
                    <RadioButtonGroupInput
                        source="media_type"
                        label="Media type"
                        choices={[
                            { id: 'photo', name: 'Photo' },
                            { id: 'video', name: 'Video' }
                        ]}
                    />

                    <BooleanInput label={t('Portrait mode')} source="portrait_mode" />

                    <FormDataConsumer>
                        {({ formData }) => formData.media_type === 'photo' && (
                            <ImageInput labelSingle={`${t('Image')} (< 3MB)`} source="image" label={t('Post image')} maxSize={3000000} accept="image/*" validate={required()}>
                                <ImageField source="src" title={t('Image')} />
                            </ImageInput>
                        )}
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData }) => formData.media_type === 'video' && (
                            <FileInput labelSingle={t('Video')} source="video" label={t('Post video')} accept="video/*" validate={required()}>
                                <FileField source="src" title="Video" />
                            </FileInput>
                        )}
                    </FormDataConsumer>
                </>
            }

            {!create &&
                <FunctionField
                    label="Image"
                    render={(record) => {
                        if (record.media_type === 'photo')
                            return (
                                <img
                                    src={`${process.env.REACT_APP_CDN_URL}/${record.name}.${record.ext}`}
                                    alt={record.description}
                                    style={{ maxWidth: '100%', height: 'auto', display: 'block'}}
                                />
                            );
                        if (record.media_type === 'video')
                            return (
                                <ReactPlayer
                                    url={`${process.env.REACT_APP_CDN_URL}/${record.name}.${record.ext}`}
                                    controls={true}
                                />
                            );
                    }}
                />
            }
        </SimpleForm>
    );
}

export const PostCreate = (props) => {
    const {t} = useTranslation();

    return (
        <Create {...props} title={t('Create post')}>
            <PostFormFields create={true}/>
        </Create>
    );
}

export const PostEdit = (props) => {
    const {t} = useTranslation();

    return (
        <Edit {...props} title={t('Edit post')} redirect="show">
            <PostFormFields create={false}/>
        </Edit>
    );
}
