import {useEffect, useState} from 'react';
import { FilterList, FilterListItem, useListContext, usePermissions } from 'react-admin';
import { Card, CardContent, IconButton } from '@mui/material';
import { CharityDashboardIcon, UmbrellaIcon } from "../icons/icons";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Add expand icons
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; // for toggling visibility
import { useTranslation } from "react-i18next";
import useUmbrellasAndCharities from "./useUmbrellasAndCharities";
import theme from '../themes';
import hasPermission from "../auth/hasPermission";

export const CharityFilterAside = ({ showCharities = true }) => {
    const { t } = useTranslation();
    const { permissions } = usePermissions();
    const [umbrellaFilterId, setUmbrellaFilterId] = useState(0);
    const { umbrellas, charities } = useUmbrellasAndCharities({ umbrellaId: umbrellaFilterId });
    const { filterValues } = useListContext();
    const [isFiltersVisible, setIsFiltersVisible] = useState(true); // State to toggle visibility
    const [cardMinimumWidth, setCardMinimumWidth] = useState(250);

    useEffect(() => {
        if (filterValues.umbrella_id) {
            const umbrellaId = parseInt(filterValues.umbrella_id);
            setUmbrellaFilterId(umbrellaId);
        } else {
            setUmbrellaFilterId(0);
        }
    }, [filterValues.umbrella_id]);

    const updateFilterVisibility = (isVisible) => {
        setCardMinimumWidth(isVisible ? 250 : 35);
        setIsFiltersVisible(isVisible);
    }

    if (!charities) return null;

    return (
        <Card sx={{ order: -1, mr: 2, mt: 8, minWidth: cardMinimumWidth, backgroundColor: '#dfefc8' }}>
            <IconButton
                onClick={() => updateFilterVisibility(!isFiltersVisible)}
                sx={{ transform: isFiltersVisible ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.3s' }}
            >
                {isFiltersVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            {isFiltersVisible && (
                <>
                    {hasPermission(permissions, ["default"], "umbrellaFilter") &&
                        <CardContent>
                            <FilterList
                                label={t('Umbrella')}
                                icon={<UmbrellaIcon />}
                                sx={{ color: theme.palette.primary.main, fontSize: 'large', fontWeight: '700' }}
                            >
                                {umbrellas
                                    ? umbrellas.map((record) => (
                                        <FilterListItem
                                            sx={{ color: theme.palette.primary.main, fontSize: 16, fontWeight: 'bold' }}
                                            label={record.charity_name}
                                            value={{ umbrella_id: record.id }}
                                            key={record.id}
                                        />
                                    ))
                                    : null
                                }
                            </FilterList>
                        </CardContent>
                    }

                    {showCharities && hasPermission(permissions, ["default"], "charityFilter") &&
                        <CardContent>
                            <FilterList
                                label={t('Charity')}
                                icon={<CharityDashboardIcon />}
                                style={{ fontWeight: '700' }}
                                sx={{ color: theme.palette.primary.main, fontSize: 'large', fontWeight: '700' }}
                            >
                                {charities
                                    ? charities.map((record) => (
                                        <FilterListItem
                                            sx={{
                                                color: theme.palette.primary.main, fontSize: 16, fontWeight: 'bold',
                                            }}
                                            label={record.charity_name}
                                            value={{ charity_id: record.id }}
                                            key={record.id}
                                        />
                                    ))
                                    : null
                                }
                            </FilterList>
                        </CardContent>
                    }
                </>
            )}
        </Card>
    );
};
