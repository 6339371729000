import * as React from 'react';
import {Box, Button, Divider, List, ListItem, ListItemText} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';
import {useGetList} from 'react-admin';
import {subDays} from 'date-fns';
import {getFormattedDateString} from "../DateHelpers";
import {useTranslation} from "react-i18next";

const NewChildren = ({umbrellaId, charityId}) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const aMonthAgo = subDays(new Date(), 30);
    aMonthAgo.setDate(aMonthAgo.getDate() - 30);
    aMonthAgo.setHours(0);
    aMonthAgo.setMinutes(0);
    aMonthAgo.setSeconds(0);
    aMonthAgo.setMilliseconds(0);

    const {data: guardians} = useGetList(
        'guardians',
        {
            sort: {field: 'createDateTime', order: 'DESC'},
            pagination: {page: 0, perPage: 5},
            filter: {charity_id : charityId, umbrella_id : umbrellaId}
        },
    );

    if (!guardians) return null;

    return (
        <div>
            <div>{t('Last guardians onboarded')}</div>
            <hr />
            <List>
                {guardians
                    ? guardians.map((record) => (
                        <div>
                            <ListItem
                                button
                                to={`/guardians/${record.id}/show`}
                                component={Link}
                                key={record.id}
                                style={{height: 60}}
                            >
                                <ListItemText
                                    primary={getFormattedDateString(record.create_date_time, 'D MMM YYYY') + ` - ${record.given_name} ${record.family_name}`}
                                />
                            </ListItem>
                            <Divider light={true}/>
                        </div>
                    ))
                    : null}
            </List>
            <Box flexGrow="1">&nbsp;</Box>
            <Button
                className={classes.link}
                component={Link}
                to="/guardians"
                size="small"
                color="primary"
            >
                <Box p={1} className={classes.linkContent}>
                    {t('Show all guardians')}
                </Box>
            </Button>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    link: {
        borderRadius: 0,
    },
    linkContent: {
        color: theme.palette.primary.main,
    },
}));

export default NewChildren;
