import {jwtDecode} from 'jwt-decode';

const authProvider = {
    login: ({username, password}) => {
        const body = {
            "grant_type": "password",
            "client_id": "nofamfe-service",
            "client_secret": process.env.REACT_APP_CLIENT_SECRET,
            "username": username,
            "password": password
        }

        return fetch(`${process.env.REACT_APP_KEYCLOAK_URL}/auth/realms/nofam/protocol/openid-connect/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            body: getFormBody(body)
        })
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(({access_token, refresh_token}) => {
                localStorage.setItem('username', username);
                localStorage.setItem('accessToken', access_token);
                localStorage.setItem('refreshToken', refresh_token);
                const decoded = jwtDecode(access_token);
                const roles = decoded['resource_access']['springboot-microservice']['roles'];
                let role = '';
                if(roles.includes('charity_admin') || roles.includes('charity_volunteer')) {
                    role = 'charity';
                } else if(roles.includes('umbrella_admin')) {
                    role = 'umbrella';
                } else if(roles.includes('admin')) {
                    role = 'admin';
                }
                localStorage.setItem('role', role);
            })
            .catch((reason) => {
                console.log('Catching reason: ' + JSON.stringify(reason));
            });
    },
    logout: () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('roles');
        localStorage.removeItem('role');
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('accessToken') ? Promise.resolve() : Promise.reject();
    },
    checkError: (error) => {
        if (error && typeof error.status !== 'undefined') {
            const status = error.status;
            if (status === 401 || status === 403) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('roles');
                localStorage.removeItem('role');
                return Promise.reject();
            }
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};

const getFormBody = (body) => {
    let formBody = [];
    for (let property in body) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return formBody;
}

export default authProvider;
