import React, {useState} from 'react';
import {
    DateField,
    FunctionField,
    RichTextField,
    Show,
    SimpleShowLayout,
    TabbedShowLayout,
    usePermissions,
    useRecordContext,
    WithRecord
} from 'react-admin';
import ReactPlayer from "react-player";
import {Avatar, TextField as MuiTextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import TranslationTable from "../components/TranslationTable";
import PostComments from "./PostComments";
import {Link} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import hasPermission from "../auth/hasPermission";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {axiosApiInstance} from "../AxiosUtils";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles({
    label: {
        '& .RaLabeled-label span': {
            color: '#FFF',
        },
    },
});

const ShowPost = () => {

    const {t} = useTranslation();

    const classes = useStyles();

    // We need to check the permissions of the users
    const {permissions} = usePermissions();

    // We need access to the record from the functions
    const record = useRecordContext();

    const [childId, setChildId] = useState(0);
    const [successAlert, setSuccessAlert] = useState(false);
    const [warningAlert, setWarningAlert] = useState(false);
    const [duplicating, setDuplicating] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    const duplicate = () => {
        if (record) {
            setDuplicating(true);
            setSuccessAlert(false);
            setWarningAlert(false);
            setErrorText("");
            axiosApiInstance.post(
                `${process.env.REACT_APP_API_URL}/api/v1/admin/post/duplicate/${record.id}/${childId}`
            ).then((response) => {
                if (response.status === 200) {
                    setDuplicating(false);
                    setSuccessAlert(true);
                    setSuccessText("Post is duplicated");
                    setWarningAlert(false);
                } else {
                    setDuplicating(false);
                    setSuccessAlert(false);
                    setSuccessText("");
                    setWarningAlert(true);
                    setErrorText("Not able to duplicate");
                }
            }).catch((reason) => {
                setDuplicating(false);
                setSuccessAlert(false);
                setSuccessText("");
                setWarningAlert(true);
                setErrorText("Not able to duplicate");
            })
        }
    }

    return (
        <>
            <SimpleShowLayout sx={{backgroundColor: "#2f3c4c", color: "#FFF"}}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: 16,
                    marginTop: 16,
                    marginBottom: 16
                }}>
                    <FunctionField render={record =>
                        <Avatar sx={{height: 100, width: 100}}
                                src={`${process.env.REACT_APP_CDN_URL}/${record.creator.avatar_thumbnail}`}/>}
                    />
                    <div style={{marginLeft: 16}}>
                        <WithRecord
                            render={record =>
                                <Link style={{color: '#ffffff'}}
                                      to={record.creator.is_volunteer ? `/volunteers/${record.creator.id}/show` : `/guardians/${record.creator.id}/show`}>
                                    <Typography component="h4" variant="h4">{record.creator.given_name}</Typography>
                                </Link>
                            }/>
                        <DateField label={t('Publication date')} source="created_time" className={classes.label}
                                   sx={{fontSize: 'medium'}}/>
                    </div>
                </div>
            </SimpleShowLayout>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label={t('Details')}>
                    <FunctionField
                        render={(record) => {
                            const child = record.child;
                            return (
                                <div>
                                    <div>
                                        <Typography
                                            style={{fontWeight: 500, fontSize: 18}}>{t('Child name')}</Typography>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginRight: 16
                                        }}>
                                            <Avatar alt={child.name}
                                                    src={`${process.env.REACT_APP_CDN_URL}/${child.profile_image}.${child.image_ext}`}
                                                    sx={{width: 56, height: 56}} style={{marginRight: 16}}/>
                                            <Link to={`/children/${child.id}/show`}>
                                                <Typography component="h6"
                                                            variant="h6">{child.name} ({child.charity.charity_name})</Typography>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        }}/>
                    <RichTextField source="description"/>
                    <FunctionField
                        label="Image"
                        sx={{ width: '80%' }}
                        render={(record) => {
                            if (record.media_type === 'photo')
                                return (
                                    <img
                                        src={`${process.env.REACT_APP_CDN_URL}/${record.name}.${record.ext}`}
                                        alt={record.description}
                                        style={{ maxWidth: '100%', height: 'auto', display: 'block'}}
                                    />
                                );
                            if (record.media_type === 'video')
                                return (
                                    <ReactPlayer
                                        url={`${process.env.REACT_APP_CDN_URL}/${record.name}.${record.ext}`}
                                        controls={true}
                                    />
                                );
                        }}
                    />
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label={t('Translations')}>
                    <FunctionField
                        render={(record) => {
                            return (
                                <TranslationTable translations={record.translations}/>
                            );
                        }}
                    />
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label={t('Comments')}>
                    <FunctionField
                        render={(record) => {
                            return (
                                <PostComments postId={record.id}/>
                            );
                        }}
                    />
                </TabbedShowLayout.Tab>
                {hasPermission(permissions, ["duplicate"], "post") &&
                    <TabbedShowLayout.Tab label="Duplicate">
                        <div>
                            <div style={{marginTop: 16}}>
                                <MuiTextField id="child_id" label="Duplicate for child ID" variant="outlined"
                                              style={{marginRight: 8}}
                                              onChange={(e) => setChildId(e.target.value)}/>
                                <LoadingButton
                                    onClick={duplicate}
                                    loading={duplicating}
                                    variant="outlined">Duplicate</LoadingButton>
                            </div>
                            <div>
                                {successAlert ? <Alert severity="success">
                                    <AlertTitle>Succes</AlertTitle>
                                    {successText}
                                </Alert> : <></>}

                                {warningAlert ? <Alert severity="warning">
                                    <AlertTitle>Warning</AlertTitle>
                                    {errorText}
                                </Alert> : <></>}
                            </div>
                        </div>
                    </TabbedShowLayout.Tab>
                }
            </TabbedShowLayout>
        </>
    );
}
export const PostShow = (props) => {
    return (
        <Show {...props} >
            <ShowPost/>
        </Show>
    );
}
