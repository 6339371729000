import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

export const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
    async (config) => {
        // Is there an access token available?
        let accessToken = localStorage.getItem('accessToken');

        // Do we still have a valid access token?
        if (isAccessTokenExpired(accessToken)) {
            accessToken = localStorage.getItem('refreshToken');
        }

        // Set the correct headers for authentication
        config.headers = {
            'Authorization': `Bearer ${accessToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        return config;
    },
    (error) => {
        console.log("Error in interceptor");
        throw error;
    },
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const refreshToken = localStorage.getItem('refreshToken');
        const originalRequest = error.config;
        if (
            refreshToken &&
            !originalRequest._retry &&
            error.response.status === 403 // Only fail
        ) {
            originalRequest._retry = true;
            const accessToken = await refreshAccessToken();
            axios.defaults.headers.Authorization = 'Bearer ' + accessToken;
            return axiosApiInstance(originalRequest);
        }

        // Another error occured
        throw error;
    },
);

export const refreshAccessToken = async () => {
    const refreshToken = await localStorage.getItem('refreshToken');
    return new Promise((resolve, reject) => {
        const res = refresh(refreshToken);
        res
            .then((response) => {
                // 200 = successful refresh
                if (response.status === 200) {
                    response.json().then((body) => {
                        try {
                            // store token in async storage
                            localStorage.setItem('accessToken', body.access_token);
                            localStorage.setItem('refreshToken', body.refresh_token);

                            resolve(body.access_token);
                        } catch (err) {
                            localStorage.removeItem('accessToken');
                            localStorage.removeItem('refreshToken');
                            reject(err);
                        }
                    });
                } else {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    reject(response);
                }
            })
            .catch(function (error) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                reject(error);
            });
    });
};

export const refresh = async (refreshToken) => {
    const body = {
        grant_type: 'refresh_token',
        client_id: 'nofamfe-service',
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        refresh_token: refreshToken,
    };

    return fetch(
        `${process.env.REACT_APP_KEYCLOAK_URL}/auth/realms/nofam/protocol/openid-connect/token`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            body: getFormBody(body),
        },
    );
};

const getFormBody = (body) => {
    let formBody = [];
    for (let property in body) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');
    return formBody;
};

const isAccessTokenExpired = (accessToken) => {
    const decoded = jwtDecode(accessToken);

    return decoded.exp < Date.now() / 1000;
};
