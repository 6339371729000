import {axiosApiInstance} from "../AxiosUtils";

export const getDashboard = (permissions, umbrellaId, charityId) => {
    const rolePath = (permissions === 'admin' ? 'admin' : 'umbrellad');

    let requestParams = '';
    if (umbrellaId > 0 || charityId > 0) {
        let params = [];
        if (umbrellaId > 0) {
            params.push(`umbrella_id=${umbrellaId}`);
        }
        if (charityId > 0) {
            params.push(`charity_id=${charityId}`);
        }
        requestParams = `?${params.join('&')}`;
    }

    return axiosApiInstance.get(`${process.env.REACT_APP_API_URL}/api/v1/${rolePath}/dashboard${requestParams}`);
}

export const getCharityDashboard = () => axiosApiInstance.get(`${process.env.REACT_APP_API_URL}/api/v1/charitad/dashboard`);
